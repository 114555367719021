import React, { cloneElement, Component, useEffect } from 'react';
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { variables } from "../Variables";
import { setquestiondetail, clearquestiondetail } from "../features/QuestionDetailSlice";
import { TypeOfAnswer } from "../eventforms/TypeOfAnswer";
import { QuestionSummary } from "../eventforms/QuestionSummary";

import closeIcon from '../assets/images/cross-close.svg';
import addDescriptionIcon from '../assets/images/add-description-icon.svg';
import QuestionAnsDeleteIcon from '../assets/images/question-ans-delete-icon.svg';

export const CreateQuestion = ({ closeCreateQuestionModal, showMessage, copiedQuestion }) => {
    const [allLanguages, setAllLanguages] = useState([]);
    const [selectLanguage, setSelectLanguage] = useState(null);
    const [descAreaField, setDescAreaField] = useState([]);
    const [questionDetailToBeSaved, setQuestionDetailToBeSaved] = useState(null);
    const [isAnyFieldEmpty, setIsAnyFieldEmpty] = useState(false);
    const reduxAllLanguage = useSelector(state => state.allLanguages.languages);

    const dispatch = useDispatch();

    const reduxQuestionDetail = useSelector(state => state.questionDetail.questionDetail);
    const user = useSelector(state => state.user.user);
    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);

    const getAllLanguages = () => {

        let getLanguages = reduxAllLanguage;
        let objQuestion = JSON.parse(JSON.stringify(questionDetailToBeSaved));

        let mappedLanguages = getLanguages.map(item => {
            objQuestion.questionDetail["questionTitle-" + item.languageCode] = "";
            objQuestion.questionDetail["questionDescription-" + item.languageCode] = "";
            return {
                id: item.id,
                languageCode: item.languageCode,
                languageDescription: item.languageDescription,
                descriptionLength: 100,
                updatedDescriptionLength: 100,
                titleLength: 60,
                updatedTitleLength: 60,
                isDescriptionEnabled: false,
                titleErrorMessage: "",
                descriptionErrorMessage: ""
            }
        })
        setQuestionDetailToBeSaved(objQuestion);
        setAllLanguages(mappedLanguages);
    }

    //useEffect(() => {
    //    if (reduxQuestionDetail != null && questionDetailToBeSaved == null && Object.keys(reduxQuestionDetail).length > 0) {
    //        setQuestionDetailToBeSaved(reduxQuestionDetail);
    //    }
    //}, [reduxQuestionDetail])

    useEffect(() => {
        if (user && copiedQuestion == null) {
            setQuestionDetailToBeSaved({
                clientId: +user.user.clientId,
                questionDetail: {},
                step: 1
            });
        }
    }, [user])

    useEffect(() => {
        if (copiedQuestion != null) {
            let mappedObject = {
                clientId: copiedQuestion.clientId,
                questionDetail: JSON.parse(copiedQuestion.questionDetail),
                step: 1
            }
            setQuestionDetailToBeSaved(mappedObject);

            let getLanguages = reduxAllLanguage;
            let mappedLanguages = getLanguages.map(item => {
                let description = mappedObject.questionDetail["questionDescription-" + item.languageCode];
                let title = mappedObject.questionDetail["questionTitle-" + item.languageCode];
                let isDescriptionEnabled = description == "" ? false : true;
                let remainingDescriptionLength = description == "" ? 100 : 100 - description.length
                let remainingTitleLength = title == "" ? 60 : 60 - title.length
                return {
                    id: item.id,
                    languageCode: item.languageCode,
                    languageDescription: item.languageDescription,
                    descriptionLength: 100,
                    updatedDescriptionLength: remainingDescriptionLength,
                    titleLength: 60,
                    updatedTitleLength: remainingTitleLength,
                    isDescriptionEnabled: isDescriptionEnabled,
                    titleErrorMessage: "",
                    descriptionErrorMessage: ""
                }
            })
            setAllLanguages(mappedLanguages);
        }
    }, [copiedQuestion])

    useEffect(() => {
        if (user && questionDetailToBeSaved && Object.keys(questionDetailToBeSaved.questionDetail).length == 0) {
            getAllLanguages();
        }
    }, [user, questionDetailToBeSaved])

    const addQuestionDescption = (item) => {
        let updatedList = allLanguages.map(lang => {
            if (lang.id == item.id)
                return { ...lang, isDescriptionEnabled: !item.isDescriptionEnabled }
            else
                return { ...lang }
        })
        setAllLanguages(updatedList);
    };

    const deleteDescription = (item) => {
        let objQuestionDetail = JSON.parse(JSON.stringify(questionDetailToBeSaved));
        objQuestionDetail.questionDetail["questionDescription-" + item.languageCode] = "";

        let updatedList = allLanguages.map(lang => {
            if (lang.id == item.id)
                return { ...lang, isDescriptionEnabled: !item.isDescriptionEnabled, updatedDescriptionLength: 100, descriptionErrorMessage: "" }
            else
                return { ...lang }
        })
        setQuestionDetailToBeSaved(objQuestionDetail);
        setAllLanguages(updatedList);
    };

    const remainingCharactersForTitle = (e, item) => {
        let value = e.target.value;
        let objQuestionDetail = JSON.parse(JSON.stringify(questionDetailToBeSaved));
        let length = value.length;
        let maxLength = item.titleLength;
        let remainingLength = maxLength - length;

        let updatedList = allLanguages.map(lang => {
            if (lang.id == item.id)
                return { ...lang, updatedTitleLength: remainingLength, titleErrorMessage: "" }
            else
                return { ...lang }
        })
        objQuestionDetail.questionDetail["questionTitle-" + item.languageCode] = value;
        setQuestionDetailToBeSaved(objQuestionDetail);
        setAllLanguages(updatedList);
    }

    const remainingCharactersForDescription = (e, item) => {
        let value = e.target.value;
        let objQuestionDetail = JSON.parse(JSON.stringify(questionDetailToBeSaved));
        let length = value.length;
        let maxLength = item.descriptionLength;
        let remainingLength = maxLength - length;

        let updatedList = allLanguages.map(lang => {
            if (lang.id == item.id)
                return { ...lang, updatedDescriptionLength: remainingLength, descriptionErrorMessage: "" }
            else
                return { ...lang }
        })
        objQuestionDetail.questionDetail["questionDescription-" + item.languageCode] = value;
        setQuestionDetailToBeSaved(objQuestionDetail);
        setAllLanguages(updatedList);
    }

    const moveToNextStep = (objFromAnswerTypeScreen) => {
        let objQuestionDetail = JSON.parse(JSON.stringify(questionDetailToBeSaved));
        if (objFromAnswerTypeScreen) {
            objQuestionDetail = objFromAnswerTypeScreen;
        }
        else {
            objQuestionDetail.step = objQuestionDetail.step + 1;
        }
        setQuestionDetailToBeSaved(objQuestionDetail);
        dispatch(setquestiondetail(objQuestionDetail));
    }

    const validateQuestionDetailAndMoveNext = () => {
        let objQuestionDetail = JSON.parse(JSON.stringify(questionDetailToBeSaved));
        let emptyFieldList = [];

        let mappedLanguages = allLanguages.map(item => {
            let qTitle = objQuestionDetail.questionDetail["questionTitle-" + item.languageCode];
            let qDescription = objQuestionDetail.questionDetail["questionDescription-" + item.languageCode];

            let languageLabel = "";
            if (item.languageDescription == "English") {
                languageLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalEnglishLbl"] : "English";
            }
            else if (item.languageDescription == "French") {
                languageLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalFrenchLbl"] : "French";
            }
            else if (item.languageDescription == "Dutch") {
                languageLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalDutchLbl"] : "Dutch";
            }

            if (qTitle == "") {
                item.titleErrorMessage = `${reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalValidErrorMessageText1"] : "Enter the title in "} ${languageLabel}`;
                emptyFieldList.push(item.titleErrorMessage);
            }
            if (item.isDescriptionEnabled && qDescription == "") {
                item.descriptionErrorMessage = `${reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalValidDescErrorMessageText"] : "Enter the description in "} ${languageLabel}`;
                emptyFieldList.push(item.descriptionErrorMessage);
            }
            return item;
        })
        setAllLanguages(mappedLanguages);
        if (emptyFieldList.length == 0) {
            moveToNextStep();
        }
    }

    //move back to previous screen of create question flow
    const moveToPreviousStep = () => {
        let objQuestionDetail = JSON.parse(JSON.stringify(questionDetailToBeSaved));
        objQuestionDetail.step = objQuestionDetail.step - 1;
        setQuestionDetailToBeSaved(objQuestionDetail);
        dispatch(setquestiondetail(objQuestionDetail));
    }

    const closeQuestionCreationForm = () => {
        setQuestionDetailToBeSaved(null);
        dispatch(clearquestiondetail());
        closeCreateQuestionModal();
    }

    const handleEditQuestion = () => {
        let objQuestionDetail = JSON.parse(JSON.stringify(questionDetailToBeSaved));
        objQuestionDetail.step = 1;
        setQuestionDetailToBeSaved(objQuestionDetail);
        dispatch(setquestiondetail(objQuestionDetail));
    }

    useEffect(() => {
        if (questionDetailToBeSaved && questionDetailToBeSaved.step == 1) {
            //showMessage("success", "Question successfully created.");
            dispatch(setquestiondetail(questionDetailToBeSaved));
        }
    }, [questionDetailToBeSaved])

    return (
        <>
            {
                questionDetailToBeSaved != null && questionDetailToBeSaved.step == 1 ?
                    <>
                        <div className="d-flex justify-content-between mb-4">
                            <ul className="form-steps">
                                <li className="step-bar active"></li>
                                <li className="step-bar"></li>
                                <li className="step-bar"></li>
                                <li className="step-bar"></li>
                            </ul>
                            <img src={closeIcon} onClick={closeQuestionCreationForm} className="cursor-pointer" />
                        </div>
                        <div>
                            <p className="form-title mb-2">{reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalTitle"] : "Question: Creation"} </p>
                        </div>
                        <div className="mb-4">
                            <p className="modal-p-text-black">
                                {reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalLoremText"] :
                                    "Lorem ipsum dolor sit amet consectetur. Ullamcorper netus sed est lorem. Facilisis dictum bibendum tellus eu eget porttitor. Arcu eget molestie sed consectetur integer. Risus malesuada id lacus egestas. Sit maecenas iaculis nibh eget habitasse ac. Fermentum pellentesque mattis enim adipiscing aliquet. Risus tellus augue lectus sit eu pharetra. Mattis fermentum nibh viverra egestas fames. Ut ut in mattis blandit magna. Laoreet arcu morbi."
                                }
                            </p>
                        </div>
                        <div className="mb-4">
                            {
                                allLanguages.length > 0 ?
                                    allLanguages.map((item) => {
                                        let title = Object.keys(questionDetailToBeSaved.questionDetail).length > 0 ? questionDetailToBeSaved.questionDetail[`questionTitle-${item.languageCode}`] : "";
                                        let description = Object.keys(questionDetailToBeSaved.questionDetail).length > 0 ? questionDetailToBeSaved.questionDetail[`questionDescription-${item.languageCode}`] : "";

                                        let languageLabel = "";
                                        let activeLabel = "";
                                        if (item.languageDescription == "English") {
                                            languageLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalEnglishLbl"] : "English";
                                            activeLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalActiveLbl"] : "Active";
                                        }
                                        else if (item.languageDescription == "French") {
                                            languageLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalFrenchLbl"] : "French";
                                            activeLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalActiveLbl"] : "Active";
                                        }
                                        else if (item.languageDescription == "Dutch") {
                                            languageLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalDutchLbl"] : "Dutch";
                                            activeLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalActiveLbl"] : "Active";
                                        }

                                        return (
                                            <div className="" key={item.id}>
                                                <div className="mb-2">
                                                    <span className="subtitle me-2">{languageLabel}</span> <span className="active-green-label px-2 py-1">{activeLabel}</span>
                                                </div>
                                                <div className="row align-items-baseline">
                                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                                        <p className="modal-p-text-black">{reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalQuestionLbl"] : "Label of the question"} <span className="text-danger">*</span> </p>
                                                    </div>
                                                    <div className={`col-sm-12 col-md-8 col-lg-8`}>
                                                        <input className="form-control w-100" maxLength="60"
                                                            defaultValue={title}
                                                            onChange={(e) => remainingCharactersForTitle(e, item)}
                                                        />
                                                        <p className="mb-0 question-feild-bottom-text position-relative">
                                                            {item.updatedTitleLength} {reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalRemainingCharLbl"] : "characters remaining"}
                                                            <span className="text-red fs-small login-field-error position-absolute top-0 end-0 mt-1px">{item.titleErrorMessage}</span>
                                                        </p>
                                                        <p className="show-question-description text-end cursor-pointer">
                                                            {
                                                                item.isDescriptionEnabled == true ?
                                                                    <></>
                                                                    :

                                                                    <span onClick={() => addQuestionDescption(item)}>
                                                                        <img src={addDescriptionIcon} className="me-2" /> {reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalAddDesBtnText"] : "Add a  description of the question"}
                                                                    </span>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    item.isDescriptionEnabled == true ?
                                                        <div className={`row align-items-baseline mt-2 d-flex}`}>
                                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                                <p className="modal-p-text-black">{reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalQuesDescriptionLbl"] : "Description of the question"}</p>
                                                            </div>
                                                            <div className="col-sm-12 col-md-8 col-lg-8">
                                                                <textarea className="form-control ques-desc-area" id="exampleFormControlTextarea1"
                                                                    defaultValue={description}
                                                                    rows={8} maxLength="100"
                                                                    onChange={(e) => remainingCharactersForDescription(e, item)}>
                                                                </textarea>
                                                                <p className="mb-0 question-feild-bottom-text position-relative">
                                                                    {item.updatedDescriptionLength} {reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalRemainingCharLbl"] : "characters remaining"}
                                                                    <span className="text-red fs-small login-field-error position-absolute top-0 end-0 mt-1px">{item.descriptionErrorMessage}</span>
                                                                </p>
                                                                <p className="hide-question-description text-end">
                                                                    <span className="text-red cursor-pointer" onClick={() => deleteDescription(item)}>{reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalDeleteDescBtn"] : "Delete Description"}</span></p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        )
                                    })
                                    :
                                    <></>
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-25 me-3" onClick={closeQuestionCreationForm}>{reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalBackBtn"] : "Back"}</button>
                            <button type="button" className="site-btn site-btn-padd w-25" onClick={validateQuestionDetailAndMoveNext}>{reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalNextBtn"] : "Next"}</button>
                        </div></>
                    :

                    questionDetailToBeSaved != null && questionDetailToBeSaved.step == 2 ?
                        <TypeOfAnswer closeQuestionCreationForm={closeQuestionCreationForm} moveToNextStep={moveToNextStep} moveToPreviousStep={moveToPreviousStep}
                            languages={allLanguages} />
                        :
                        questionDetailToBeSaved != null && questionDetailToBeSaved.step == 3 ?
                            <QuestionSummary closeQuestionCreationForm={closeQuestionCreationForm} moveToNextStep={moveToNextStep} moveToPreviousStep={moveToPreviousStep}
                                languages={allLanguages} showMessage={showMessage} handleEditQuestion={handleEditQuestion} />
                        :
                        <></>
            }

        </>
    )
}