import * as React from "react";
import { useSelector } from "react-redux";
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

import closeIcon from '../assets/images/cross-close.svg';
const { useState, useEffect } = React;

export const ConfirmDeleteWindow = ({ showConfirmWindow, handleConfirmWindowClose, callBackConfirmDeleteFreeSlots, displayMessage }) => {
    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);

    const confirmOrCancelDeleteEvents = (e) => {
        let id = e.target.id;
        if(id == "confirm"){
            callBackConfirmDeleteFreeSlots(true);
        }
        else{
            callBackConfirmDeleteFreeSlots(false);
        }
    }
    return (
        <>
            <Modal show={showConfirmWindow} onHide={handleConfirmWindowClose} className="modalStyle p-0">
                <Modal.Body className="p-0">
                    <div className="modal-container">
                        <div className="d-flex justify-content-between mb-4">
                            <p className="form-title">{reduxLanguageTranslations ? reduxLanguageTranslations["eventDeleteWindowModalTitle"] : "Confirm Delete"} </p>
                            <img src={closeIcon} onClick={handleConfirmWindowClose} className="cursor-pointer" />
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                            {displayMessage}
                        </div>
                        <div className="d-flex">
                            <button className="filter-btn site-btn-padd border-dark color-dark w-100 me-3" id="cancel" onClick={confirmOrCancelDeleteEvents}>{reduxLanguageTranslations ? reduxLanguageTranslations["eventDeleteWindowModalCancelBtn"] : "Cancel"}</button>
                            <button className="site-btn site-btn-padd w-100" id="confirm" onClick={confirmOrCancelDeleteEvents}>{reduxLanguageTranslations ? reduxLanguageTranslations["eventDeleteWindowModalConfirmBtn"] : "Confirm"}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}





