import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from '../features/userSlice';
import whiseTokenReducer from '../features/WhiseTokenSlice';
import selectedPropertyReducer from '../features/SelectedPropertySlice';
import agentFilterReducer from '../features/AgentFilterSlice';
import statusFilterReducer from '../features/StatusFilterSlice';
import createEventDetailSlice from '../features/CreateEventDetailSlice';
import SettingReducer from '../features/SettingSlice';
import CalendarActionsReducer from '../features/ClientCalendarActionsSlice';
import ClientTokenReducer from '../features/ClientTokenSlice';
import CalendarViewReducer from '../features/CalendarSelectedViewSlice';
import SidebarCollapseReducer from '../features/SidebarCollapseSlice';
import QuestionDetailReducer from '../features/QuestionDetailSlice';
import LanguagesReducer from '../features/LanguageSlice';
import estateConfigurationReducer from '../features/EstateConfigurationSlice';
import selectedLanguageTranslationsReducer from '../features/SelectedLanguageTranslationsSlice';
import storage from 'redux-persist/lib/storage';
import {
    persistReducer, persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER } from 'redux-persist';
import storageSession from 'redux-persist/es/storage/session';

const rootReducer = combineReducers({
    user: userReducer,
    whiseUser: whiseTokenReducer,
    selectedProperty: selectedPropertyReducer,
    agentFilter: agentFilterReducer,
    statusFilter: statusFilterReducer,
    eventDetail: createEventDetailSlice,
    setting: SettingReducer,
    calendarActions: CalendarActionsReducer,
    whiseClientToken: ClientTokenReducer,
    calendarView: CalendarViewReducer,
    isSideBarCollapsed: SidebarCollapseReducer,
    questionDetail: QuestionDetailReducer,
    allLanguages: LanguagesReducer,
    estateConfiguration: estateConfigurationReducer,
    selectedLanguageTranslations: selectedLanguageTranslationsReducer
})

const persistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: ['questionDetail', 'estateConfiguration', 'selectedLanguageTranslations']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
})

export const persistor = persistStore(store);