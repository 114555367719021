import React, { Component, useState, cloneElement, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import axios from 'axios';
import './custom.css';
import { Login } from "./components/Login";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { logout } from "./features/userSlice";
import { setlanguagetranslations, resetlanguagetranslations, setselectedlanguage } from "./features/SelectedLanguageTranslationsSlice";
import { Sidebar } from "./components/Sidebar";
import { Dropdown } from 'react-bootstrap';
import successTick from './assets/images/success_tick.svg';
import exclamation from './assets/images/error_cross.svg';
import cross from './assets/images/cross-close.svg';
import { EventHandler } from './components/EventHandler';
import { EventDenialHandler } from './components/EventDenialHandler';
import { variables } from './Variables';

const App = () => {
    const user = useSelector(state => state.user.user);
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [display, setDisplay] = useState("d-none");
    const [clientSettings, setClientSettings] = useState(null);
    const [translationsForAllLanguages, setTranslationsForAllLanguages] = useState(null);
    const [selectedAgentLanguageTranslations, setSelectedAgentLanguageTranslations] = useState(null);
    const [allLanguageForDropwon, setAllLanguageForDropwon] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    const dispatch = useDispatch();

    const whiseUserToken = useSelector(state => state.whiseUser.whiseToken);
    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);

    const url = window.location.href;

    const date = new Date();
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    const currentDateTime = date.toISOString();

    const expiryDate = user != null && user.expiryDateTime != undefined ? new Date(user.expiryDateTime) : new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() - expiryDate.getTimezoneOffset());
    const expiryDateTime = expiryDate.toISOString();

    const showMessage = (type, message) => {
        setMessageType(type);
        setMessage(message);
        setDisplay("d-block");
        const timer = setTimeout(() => {
            setMessageType("");
            setMessage("");
            setDisplay("d-none");

            return () => clearTimeout(timer);
        }, [3000]);
    }

    const getClientSettings = () => {
        let url = 'https://api.whise.eu/v1/admin/clients/settings';

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${whiseUserToken}`
        };

        if (user != null && user.user != undefined) {
            let body = {
                ClientId: user.user.clientId
            };


            axios.post(url, body,
                {
                    headers: headers
                })
                .then(response => {
                    setClientSettings(response.data.settings);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    const getLanguageTranslations = () => {
        const jsonconfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetTranslationsForSiteByLang?siteName=agent`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let translations = response.data;
                console.log(translations);
                if (Object.keys(translations).length > 0) {
                    setTranslationsForAllLanguages(translations);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getAllLanguage = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetAllLanguages`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let allLanguage = response.data;
                setAllLanguageForDropwon(allLanguage);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const changeHandlerSelectedLanguage = (e) => {
        const selectedText = e.target.innerText;
        setShowDropdown(false);
        setSelectedLanguage(selectedText);
        dispatch(setselectedlanguage(selectedText));
        dispatch(setlanguagetranslations(translationsForAllLanguages[selectedText]));
    }

    useEffect(() => {
        if (clientSettings && translationsForAllLanguages) {
            dispatch(setlanguagetranslations(translationsForAllLanguages[clientSettings.defaultLanguageId]));
            dispatch(setselectedlanguage(clientSettings.defaultLanguageId));
            setSelectedLanguage(clientSettings.defaultLanguageId)
        }
    }, [translationsForAllLanguages, clientSettings])

    useEffect(() => {
        if (whiseUserToken != null) {
            getClientSettings();
            getAllLanguage();
            getLanguageTranslations();
        }
    }, [whiseUserToken])

    return (
        <>
            <div id="messageBox" className={`message-box ${messageType == "error" && "error-box" || "success-box"} ${display}`}>
                <div className="message-container">
                    <img src={cross} className="close-message-box" />
                    {
                        messageType == "error" ?
                            <img src={exclamation} className="mb-3" />
                            :
                            <img src={successTick} className="mb-3" />
                    }
                    <p className="message-box-t mb-3">{message}</p>
                    <button className="site-btn">
                        {
                            messageType == "error" ?
                                reduxLanguageTranslations ? reduxLanguageTranslations["messageModalMessageTypeOops"] : "Oops!"
                                :
                                reduxLanguageTranslations ? reduxLanguageTranslations["messageModalMessageTypeGreat"] : "Great!"
                        }
                    </button>
                </div>
            </div>
            {
                user && currentDateTime < expiryDateTime ? <Layout>
                    <Sidebar />
                    <div className="container-fluid main pb-4">
                        <div className="d-flex justify-content-between pt-4 pb-5 px-4">
                            <div>
                                <h5 className="page-heading mb-0 me-4">
                                    {clientSettings != null ? clientSettings.name : ""}
                                </h5>
                            </div>
                            <div className="d-flex align-items-center">

                                <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
                                    <Dropdown.Toggle className="rounded-pill me-2" id="dropdown-basic">
                                        {selectedLanguage}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            allLanguageForDropwon.length > 0 ?
                                                allLanguageForDropwon.map((item, i) => {
                                                    return (
                                                        <button className="lang-dropdown-btn" onClick={changeHandlerSelectedLanguage} key={i}>{item.languageCode}</button>
                                                    )
                                                })
                                                :
                                                <></>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle className="rounded-pill" id="dropdown-basic">
                                        {user.user.userName}
                                        <span className="customer-id">
                                            {reduxLanguageTranslations != null ? reduxLanguageTranslations["topHeaderIDLabel"] : "ID"}
                                            : {user.user.userId}</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                let clonedElement = cloneElement(element, { showMessage: showMessage });
                                if (index == 0) {
                                    clonedElement = cloneElement(element, { showMessage: showMessage })
                                }
                                if (index == 3) {
                                    clonedElement = cloneElement(element, { showMessage: showMessage, clientSettings: clientSettings })
                                }
                                return <Route key={index} {...rest} element={clonedElement} />;
                            })}
                        </Routes>
                    </div>
                </Layout>
                    :
                    <Layout>
                        <div className="container-fluid">
                            {
                                url.indexOf("eventhandler") > 0 ?
                                    <Routes>
                                        <Route path="/eventhandler/:contactId?/:eventId?/:clientId?/:officeId?/:contactFirstName?/:contactName?/:contactLanguage?" element={<EventHandler />} />
                                    </Routes>
                                    : url.indexOf("eventdenialhandler") > 0 ?
                                        <Routes>
                                            <Route path="/eventdenialhandler/:contactId?/:eventId?/:clientId?/:officeId?/:contactName?/:contactLanguage?" element={<EventDenialHandler />} />
                                        </Routes>
                                        :
                                        <Login />
                            }
                        </div>
                    </Layout>
            }
        </>

    );
}

export default App;