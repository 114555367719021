import { createSlice } from "@reduxjs/toolkit";

export const SelectedLanguageTranslationsSlice = createSlice({
    name: "selectedLanguageTranslations",
    initialState: {
        selectedLanguageTranslations: null,
        selectedLanguage: null
    },
    reducers: {
        setlanguagetranslations: (state, action) => {
            state.selectedLanguageTranslations = action.payload;
        },
        resetlanguagetranslations: (state, action) => {
            state.selectedLanguageTranslations = null;
        },
        setselectedlanguage: (state, action) => {
            state.selectedLanguage = action.payload;
        },
        resetselectedlanguage: (state, action) => {
            state.selectedLanguage = null;
        }
    }
})


export const { setlanguagetranslations, resetlanguagetranslations, setselectedlanguage, resetselectedlanguage } = SelectedLanguageTranslationsSlice.actions;

export default SelectedLanguageTranslationsSlice.reducer;

