import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setestateconfig, clearestateconfig, reorderQuestions } from "../features/EstateConfigurationSlice";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';

import closeIcon from '../assets/images/cross-close.svg';
import ascendingActive from '../assets/images/ascending-active.svg';
import ascendingDisabled from '../assets/images/ascending-disabled.svg';
import descendingActive from '../assets/images/descending-active.svg';
import descendingDisabled from '../assets/images/descending-disabled.svg';
import cogWithBg from '../assets/images/cog-wd-bg.svg';
import crossIcon from '../assets/images/del-btn-icon.svg';
import editIcon from '../assets/images/edit-icon.svg';
import { variables } from '../Variables';

export const SelectionSummary = ({ closeSelectionSummaryModal, maxOrder, clickHandlerMoveToConfigSummary, setSelectedQuestions,
    createEditQuestion, freeTextForQuestions, setFreeTextForQuestions }) => {
    const [isAscending, setIsAscending] = useState(false);
    const [sortedQuestions, setSortedQuestions] = useState([]);
    const [showFreeTextBlock, setShowFreeTextBlock] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const reduxEstateConfigs = useSelector(state => state.estateConfiguration.estateConfiguration);
    const allLanguage = useSelector(state => state.allLanguages.languages);
    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);

    useEffect(() => {
        if (reduxEstateConfigs) {
            const _sortedQuestions = reduxEstateConfigs && reduxEstateConfigs.length > 0 ? [...reduxEstateConfigs].sort((a, b) => a.order - b.order) : [];
            setSortedQuestions(_sortedQuestions);
        }
    }, [reduxEstateConfigs])

    // Handler to delete an item by ID
    const handleDelete = (id) => {
        let questionsList = [...reduxEstateConfigs];

        const updatedQuestions = questionsList.map((question) => {
            if (question.id === id) {
                const _questionDetail = {
                    ...JSON.parse(question.questionDetail),
                    isChecked: false,
                };

                return {
                    ...question,
                    questionDetail: JSON.stringify(_questionDetail),
                };
            }
            return question;
        });

        const finalQuestions = updatedQuestions.filter((question) => question.id !== id);
        setSelectedQuestions(finalQuestions);
        dispatch(setestateconfig(finalQuestions));
    };

    const changeHandleMarkQuestionMandatory = (e, item) => {
        let checked = e.target.checked;
        let questionsList = [...reduxEstateConfigs];

        // Toggle the isChecked state of the clicked item
        const updatedQuestions = questionsList.map((question) => {
            if (question.id === item.id) {
                return {
                    ...question,
                    isMandatory: checked,
                };
            }
            return question;
        });
        dispatch(setestateconfig(updatedQuestions));
    }

    const handleKeyDown = (e) => {
        // Prevent up and down arrow keys from modifying the value
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
        }
    };

    const moveUp = (order) => {
        //if (index === 0) return;
        //dispatch(reorderQuestions({ fromIndex: index, toIndex: index - 1 }));

        const currentQuestion = sortedQuestions.find(q => q.order === order);
        if (currentQuestion && currentQuestion.order > 0) {
            const targetOrder = currentQuestion.order - 1;
            dispatch(reorderQuestions({ currentOrder: currentQuestion.order, targetOrder }));
        }
    };

    const moveDown = (order) => {
        const currentQuestion = sortedQuestions.find(q => q.order === order);
        if (currentQuestion && currentQuestion.order < sortedQuestions.length) {
            const targetOrder = currentQuestion.order + 1;
            dispatch(reorderQuestions({ currentOrder: currentQuestion.order, targetOrder }));
        }
    };

    const moveToNextScreen = () => {
        dispatch(setestateconfig(sortedQuestions));
        clickHandlerMoveToConfigSummary()
    }

    const onChangeHandleFreeTextBlock = (e, languageCode) => {
        let value = e.target.value;
        let freeTextForQuestionsObj = freeTextForQuestions == null ? {} : { ...freeTextForQuestions };

        if (Object.keys(freeTextForQuestionsObj).length == 0) {
            allLanguage.forEach(lang => {
                freeTextForQuestionsObj["freeText-" + lang.languageCode] = "";
            })
        }
        freeTextForQuestionsObj["freeText-" + languageCode] = value;
        setFreeTextForQuestions(freeTextForQuestionsObj);
    }

    const showHidehandleFreeText = (e) => {
        if (showFreeTextBlock) {
            setFreeTextForQuestions(null);
        }
        setShowFreeTextBlock(previous => !previous)
    }

    useEffect(() => {
        if (freeTextForQuestions != null) {
            setShowFreeTextBlock(true);
        }
    }, [freeTextForQuestions])

    return (
        <>
            <div className="d-flex justify-content-between mb-4">
                <ul className="form-steps">
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar active"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                </ul>
                <img src={closeIcon} onClick={closeSelectionSummaryModal} className="cursor-pointer" />
            </div>
            <div className="mb-4">
                <p className="form-title mb-4"> {reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalTitle"] : "Summary"} </p>
            </div>
            <div className="tabs-container position-relative">
                <button className="btn-green-add" onClick={(e) => showHidehandleFreeText(e)}>{showFreeTextBlock ? reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalRemoveTextBtn"] : "Remove Free Text" : reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalFreeTextBtn"]: "Add Free Text"} </button>
                <Tabs defaultActiveKey="English" id="justify-tab-example" className="mb-3" justify>
                    {
                        allLanguage ? allLanguage.map((lang, i) => {

                            let languageLabel = "";
                            if (lang.languageDescription == "English") {
                                languageLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalEnglishLbl"] : "English";
                            }
                            else if (lang.languageDescription == "French") {
                                languageLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalFrenchLbl"] : "French";
                            }
                            else if (lang.languageDescription == "Dutch") {
                                languageLabel = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalDutchLbl"] : "Dutch";
                            }

                            const yesLabel = lang.languageCode === "en-GB" ? "Yes" : lang.languageCode === "fr-BE" ? "Oui" : "Ja";
                            const noLabel = lang.languageCode === "en-GB" ? "No" : lang.languageCode === "fr-BE" ? "Non" : "Nee";

                            return (
                                <Tab eventKey={lang.languageDescription} title={languageLabel} key={`lang${i}`}>
                                    <div className={`mb-3 ${showFreeTextBlock ? "" : "d-none"}`}>
                                        <label className="subtitle mb-1">{reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalFreeTextFeildLabel"] : "Free text for questions in"} {languageLabel}</label>
                                        <textarea className="form-control" placeholder={reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalFreeTextPlaceHolder"] : "Enter free text for Questions and Document Types"}
                                            onChange={(e) => onChangeHandleFreeTextBlock(e, lang.languageCode)}
                                            style={{ height: "100px" }}
                                            id={`freeText${lang.languageDescription}`}
                                            value={freeTextForQuestions != null && freeTextForQuestions["freeText-" + lang.languageCode] ? freeTextForQuestions["freeText-" + lang.languageCode] : ""}
                                        >
                                        </textarea>
                                    </div>
                                    {
                                        sortedQuestions ? sortedQuestions.map((item, index) => {
                                            let detail = JSON.parse(item.questionDetail);
                                            let answerOptions = detail.answerOptions;
                                            return (
                                                <>
                                                    <div className="question-summary-container position-relative p-3 mb-3" id={item.order} key={item.id}>
                                                        <ul className="selection-summary-icons-list">
                                                            <li>
                                                                <label className="modal-p-text-black d-flex align-items-center line-height-0" htmlFor={`question${item.id}${lang.id}`}>
                                                                    <input className="form-check-input checkbox-red me-2 mt-0" type="checkbox" checked={item.isMandatory} name={`question${item.id}`} id={`question${item.id}${lang.id}`} onChange={(e) => changeHandleMarkQuestionMandatory(e, item)} />
                                                                    {reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalMandatoryCheckBox"] : "Make it mandatory"}<span className="required-icon">*</span>
                                                                </label>
                                                            </li>
                                                            <li className="ps-3 pe-3">
                                                                {
                                                                    item.order == 0 ?
                                                                        <>
                                                                            <img src={descendingDisabled} className="me-1" alt="move up" />
                                                                            <img src={ascendingActive} onClick={() => moveDown(item.order)} alt="move down" />
                                                                        </>
                                                                        :
                                                                        item.order > 0 && item.order < maxOrder ?
                                                                            <>
                                                                                <img src={descendingActive} onClick={() => moveUp(item.order)} className="me-1" alt="move up" />
                                                                                <img src={ascendingActive} onClick={() => moveDown(item.order)} alt="move down" />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <img src={descendingActive} onClick={() => moveUp(item.order)} className="me-1" alt="move up" />
                                                                                <img src={ascendingDisabled} alt="move down" />
                                                                            </>
                                                                }
                                                            </li>
                                                            <li>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle className="del-question-drop-btn bg-transparent border-0 p-0" variant="light" id={`dropdownMenuButton${item.id}`}>
                                                                        <img src={cogWithBg} />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="left-aligned-dropdown del-question-menu text-center p-0">
                                                                        <button className="btn btn-white small-grey-text text-red d-flex align-items-center" onClick={() => handleDelete(item.id)}>
                                                                            <img src={crossIcon} className="me-1" />{reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalDeleteBtn"] : "Delete"}
                                                                        </button>
                                                                        <hr className="m-0" />
                                                                        <button className="btn btn-white small-grey-text text-dark d-flex align-items-center w-100" onClick={() => createEditQuestion(item)}>
                                                                            <img src={editIcon} className="me-1" />{reduxLanguageTranslations ? reduxLanguageTranslations["quesSummaryModalEditBtn"] : "Edit"}
                                                                        </button>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </li>
                                                        </ul>
                                                        <div className="mb-4">
                                                            <p className="subtitle mb-0">{reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalQuTitle"] : "Title of the question"}</p>
                                                            <p className="modal-p-text-black mb-0">
                                                                {detail ? detail["questionTitle-" + lang.languageCode] : ""}
                                                            </p>
                                                        </div>
                                                        <div className="mb-4">
                                                            <p className="subtitle mb-0">{reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalDescQuestion"] : "Description of the question"}</p>
                                                            <p className="modal-p-text-black mb-0">
                                                                {detail ? detail["questionDescription-" + lang.languageCode] : ""}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="subtitle mb-1">{reduxLanguageTranslations ? reduxLanguageTranslations["selectionSummaryModalAnsLabel"] : "Answers"}</p>
                                                            <div>
                                                                {
                                                                    detail.answerType == 2 || detail.answerType == 3 && answerOptions.length > 0 ?
                                                                        answerOptions.map((option) => {
                                                                            let label = option[`option${option.id}-${lang.languageCode}`];
                                                                            return (
                                                                                <div className="mb-2" key={option.id}>
                                                                                    <label className="modal-p-text-black asnwer-options-preview d-flex align-items-center mb-2 w-100 me-2 line-height-0" htmlFor={`answerOption${lang.id}${option.id}`}>
                                                                                        <input className="custom-radio me-2" type="radio" name={`answerOption${lang.id}`} id={`answerOption${lang.id}${option.id}`} />
                                                                                        {
                                                                                            label
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        detail.answerType == 1 ?
                                                                            <div className="d-flex col-4">
                                                                                <label className="modal-p-text-black asnwer-options-preview d-flex align-items-center w-50 line-height-0 me-2" htmlFor={`radio${lang.id}`}>
                                                                                    <input className="custom-radio me-2" type="radio" name={`radio${lang.id}`} id={`radio${lang.id}`} />
                                                                                    {reduxLanguageTranslations ? yesLabel : reduxLanguageTranslations["quesSummaryModalAnsRadioOptionYes"]}
                                                                                </label>

                                                                                <label className="modal-p-text-black asnwer-options-preview d-flex align-items-center w-50 line-height-0" htmlFor={`radio${lang.id}${detail.answerType}`}>
                                                                                    <input className="custom-radio me-2" type="radio" name={`radio${lang.id}`} id={`radio${lang.id}${detail.answerType}`} />
                                                                                    {reduxLanguageTranslations ? noLabel : reduxLanguageTranslations["quesSummaryModalAnsRadioOptionNo"]}
                                                                                </label>
                                                                            </div>
                                                                            :
                                                                            detail.answerType == 4 || detail.answerType == 5 ?
                                                                                <>
                                                                                    {
                                                                                        detail.answerType == 4 ?
                                                                                            <input type="number" className={`form-control disabled mb-2 no-spinner`}
                                                                                                onPaste={(e) => e.preventDefault()} onKeyDown={handleKeyDown} disabled
                                                                                            />
                                                                                            :
                                                                                            <input type="text" className={`form-control disabled mb-2`} disabled />
                                                                                    }

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <input type="date" className="form-control disabled mb-2" disabled />
                                                                                </>
                                                                }
                                                                {/*{*/}
                                                                {/*    answerOptions.length > 0 ?*/}
                                                                {/*        answerOptions.map((option) => {*/}
                                                                {/*            let label = option[`option${option.id}-${lang.languageCode}`];*/}
                                                                {/*            return (*/}
                                                                {/*                <div className="mb-2" key={option.id}>*/}
                                                                {/*                    <label className="modal-p-text-black asnwer-options-preview d-flex align-items-center mb-2 w-100 me-2 line-height-0" htmlFor={`answerOption${item.id}${option.id}`}>*/}
                                                                {/*                        <input className="custom-radio me-2" type="radio" name={`answerOption${item.id}`} id={`answerOption${item.id}${option.id}`} />*/}
                                                                {/*                        {*/}
                                                                {/*                            label*/}
                                                                {/*                        }*/}
                                                                {/*                    </label>*/}
                                                                {/*                </div>*/}
                                                                {/*            )*/}
                                                                {/*        })*/}
                                                                {/*        :*/}
                                                                {/*        <></>*/}
                                                                {/*}*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                            :
                                            <></>
                                    }
                                </Tab>
                            )
                        })
                            :
                            <></>
                    }
                </Tabs>
            </div>
            <div className="d-flex justify-content-center">
                <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-25 me-3" onClick={closeSelectionSummaryModal}>{reduxLanguageTranslations ? reduxLanguageTranslations["typeOfAnsModalBackBtn"] : "Back"}</button>
                <button type="button" className="site-btn site-btn-padd w-25" onClick={moveToNextScreen}>{reduxLanguageTranslations ? reduxLanguageTranslations["typeOfAnsModalNextBtn"] : "Next"}</button>
            </div>
        </>
    )
}
