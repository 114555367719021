import * as React from "react";
import * as ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { variables, validEmail } from '../Variables';

const { useState } = React;

export const Settings = ({ showMessage, clientSettings }) => {
    const whiseUserToken = useSelector(state => state.whiseUser.whiseToken);
    const user = useSelector(state => state.user.user);
    const actions = useSelector(state => state.calendarActions.calendarActions);
    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);

    const [clientId, setClientId] = useState(user.user.clientId);
    const [officeId, setOfficeId] = useState(user.user.officeId);
    const [settingId, setSettingId] = useState("");
    const [office, setOffice] = useState({});
    const [calendarActions, setCalendarActions] = useState([]);
    const [saleVisitBefore, setSaleVisitBefore] = useState("");
    const [saleVisitAfter, setSaleVisitAfter] = useState("");
    const [rentVisitBefore, setRentVisitBefore] = useState("");
    const [rentVisitAfter, setRentVisitAfter] = useState("");
    const [rentVisitFreeSlot, setRentVisitFreeSlot] = useState("");
    const [saleVisitFreeSlot, setSaleVisitFreeSlot] = useState("");
    const [isSaleVisitBeforeEmpty, setIsSaleVisitBeforeEmpty] = useState("");
    const [isSaleVisitAfterEmpty, setIsSaleVisitAfterEmpty] = useState("");
    const [isRentVisitBeforeEmpty, setIsRentVisitBeforeEmpty] = useState("");
    const [isRentVisitAfterEmpty, setIsRentVisitAfterEmpty] = useState("");
    const [isSaleVisitFreeSlotEmpty, setIsSaleVisitFreeSlotEmpty] = useState("");
    const [isRentVisitFreeSlotEmpty, setIsRentVisitFreeSlotEmpty] = useState("");
    const [validationMessage, setValidationMessage] = useState("");
    const [isAcceptenceEmailDisabled, setIsAcceptenceEmailDisabled] = useState(false);
    const [isFreeSlotWhiseSyncEnabled, setIsFreeSlotWhiseSyncEnabled] = useState(false);

    var scrollToElement = document.getElementById("visitToSaleBefore");

    const getWhiseOffices = () => {
        let url = "https://api.whise.eu/v1/admin/offices/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${whiseUserToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, { "clientId": clientId }, config)
            .then(response => {
                if (response.data.offices != undefined) {
                    var _filteredOffice = response.data.offices.filter((item) => { return item.id == officeId });
                    setOffice(_filteredOffice[0]);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const handlerSaveSetting = (e) => {
        e.target.setAttribute("disabled", true);
        e.target.style.cursor = "progress";
        document.querySelector("body").style.cursor = "progress";
        setValidationMessage("");
        let flagEmpty = false;
        if (saleVisitBefore == "") {
            setIsSaleVisitBeforeEmpty("empty");
            flagEmpty = true;
        }
        if (saleVisitAfter == "") {
            setIsSaleVisitAfterEmpty("empty");
            flagEmpty = true;
        }
        if (rentVisitBefore == "") {
            setIsRentVisitBeforeEmpty("empty");
            flagEmpty = true;
        }
        if (rentVisitAfter == "") {
            setIsRentVisitAfterEmpty("empty");
            flagEmpty = true;
        }
        if (rentVisitFreeSlot == "") {
            setIsRentVisitFreeSlotEmpty("empty");
            flagEmpty = true;
        }
        if (saleVisitFreeSlot == "") {
            setIsSaleVisitFreeSlotEmpty("empty");
            flagEmpty = true;
        }

        if (flagEmpty == true) {
            setValidationMessage(reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageRequiredFieldsMessage"] : "Please fill the required fields");
            scrollToElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            e.target.removeAttribute("disabled");
            e.target.style.cursor = "";
            document.querySelector("body").style.cursor = "";
            return;
        }

        let settingsObj = {
            "SettingId": settingId == "" ? 0 : +settingId,
            "VisitToSaleBefore": +saleVisitBefore,
            "VisitToSaleAfter": +saleVisitAfter,
            "VisitToRentBefore": +rentVisitBefore,
            "VisitToRentAfter": +rentVisitAfter,
            "VisitToRentFreeSlot": +rentVisitFreeSlot,
            "VisitToSaleFreeSlot": +saleVisitFreeSlot,
            "OfficeId": +officeId,
            "IsAcceptenceEmailDisabled": isAcceptenceEmailDisabled,
            "SyncEventsToWhise": isFreeSlotWhiseSyncEnabled
        }

        let url = variables.API_URL + `Settings/SaveSetting?`;

        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        axios.post(url, JSON.stringify(settingsObj), jsonconfig) // ASP.NET Core API endpoint with header
            .then(response => {
                if (response.status == 200) {
                    showMessage("success", response.data);
                    e.target.removeAttribute("disabled");
                    e.target.style.cursor = "";
                    document.querySelector("body").style.cursor = "";
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                e.target.removeAttribute("disabled");
                e.target.style.cursor = "";
                document.querySelector("body").style.cursor = "";
            });
    }

    const handlerVisitToSaleBefore = (e) => {
        setSaleVisitBefore(e.target.value);
        setIsSaleVisitBeforeEmpty("");
    }

    const handlerVisitToSaleAfter = (e) => {
        setSaleVisitAfter(e.target.value);
        setIsSaleVisitAfterEmpty("");
    }

    const handlerVisitToRentBefore = (e) => {
        setRentVisitBefore(e.target.value);
        setIsRentVisitBeforeEmpty("");
    }

    const handlerVisitToRentAfter = (e) => {
        setRentVisitAfter(e.target.value);
        setIsRentVisitAfterEmpty("");
    }

    const handlerVisitToSaleFreeSlot = (e) => {
        setSaleVisitFreeSlot(e.target.value);
        setIsSaleVisitFreeSlotEmpty("");
    }

    const handlerVisitToRentFreeSlot = (e) => {
        setRentVisitFreeSlot(e.target.value);
        setIsRentVisitFreeSlotEmpty("");
    }

    const handlerResetSettings = () => {
        setSaleVisitBefore("");
        setSaleVisitAfter("");
        setRentVisitBefore("");
        setRentVisitAfter("");
        setSaleVisitFreeSlot("");
        setRentVisitFreeSlot("");
        setIsSaleVisitBeforeEmpty("");
        setIsSaleVisitAfterEmpty("");
        setIsRentVisitBeforeEmpty("");
        setIsRentVisitAfterEmpty("");
        setIsSaleVisitFreeSlotEmpty("");
        setIsRentVisitFreeSlotEmpty("");
        setValidationMessage("");
        scrollToElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    const getOfficeSettings = () => {
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetSetting?officeId=` + officeId;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                if (response.data != null) {
                    var settings = response.data;
                    setSettingId(settings.settingId)
                    setSaleVisitBefore(settings.visitToSaleBefore);
                    setSaleVisitAfter(settings.visitToSaleAfter);
                    setRentVisitBefore(settings.visitToRentBefore);
                    setRentVisitAfter(settings.visitToRentAfter);
                    setRentVisitFreeSlot(settings.visitToRentFreeSlot);
                    setSaleVisitFreeSlot(settings.visitToSaleFreeSlot);
                    setIsAcceptenceEmailDisabled(settings.isAcceptenceEmailDisabled == null ? false : settings.isAcceptenceEmailDisabled);
                    setIsFreeSlotWhiseSyncEnabled(settings.syncEventsToWhise == null ? false : settings.syncEventsToWhise);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        getWhiseOffices();
        getOfficeSettings();
    }, [clientId])

    useEffect(() => {
        if (actions != null && actions != undefined) {
            setCalendarActions(actions);
        }
    }, [actions])

    return (
        <div className="px-4">
            <div className="row">
                <div className="col-12">
                    <span className="form-title">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageCalendarSettingsLbl"] : "Calendar Settings" }<span className="text-danger">{validationMessage}</span></span>
                    <div className="form-container mt-3 mb-4">
                        <div className="row">
                            <div className="col-sm-3">
                                <span className="form-heading">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSmartCalLabelsText"] : "SmartCal labels"}</span>
                            </div>
                            <div className="col-sm-3">
                                <span className="form-heading">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageFreeSlotLbl"] : "Free Slot"}</span>
                            </div>
                            <div className="col-sm-3">
                                <span className="form-heading">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageBeforeConfirmationLbl"] : "Before Confirmation"}</span>
                            </div>
                            <div className="col-sm-3">
                                <span className="form-heading">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageAfterConfirmationLbl"] : "After Confirmation"}</span>
                            </div>
                        </div>
                        <div className="row py-4 d-flex align-items-center">
                            <div className="col-3">
                                <span className="form-label">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageVisitToSaleLbl"] : "Visit (to Sale)"}</span>
                            </div>
                            <div className="col-3">
                                <select className={`form-select ${isSaleVisitFreeSlotEmpty}`} id="visitToSaleFreeSlot" value={saleVisitFreeSlot}
                                    onChange={handlerVisitToSaleFreeSlot}>
                                    <option value="">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    {
                                        calendarActions.length > 0 ? calendarActions.map((item, index) => {
                                            return (
                                                <option value={item.id} key={index}>{item.name}</option>
                                            )
                                        }) :
                                            <option>{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    }
                                </select>
                            </div>
                            <div className="col-3">
                                <select className={`form-select ${isSaleVisitBeforeEmpty}`} id="visitToSaleBefore" value={saleVisitBefore}
                                    onChange={handlerVisitToSaleBefore}>
                                    <option value="">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    {
                                        calendarActions.length > 0 ? calendarActions.map((item, index) => {
                                            return (
                                                <option value={item.id} key={index}>{item.name}</option>
                                            )
                                        }) :
                                            <option>{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    }
                                </select>
                            </div>
                            <div className="col-3">
                                <select className={`form-select ${isSaleVisitAfterEmpty}`} id="visitToSaleAfter" value={saleVisitAfter}
                                    onChange={handlerVisitToSaleAfter}>
                                    <option value="">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    {
                                        calendarActions.length > 0 ? calendarActions.map((item, index) => {
                                            return (
                                                <option value={item.id} key={index}>{item.name}</option>
                                            )
                                        }) :
                                            <option>{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 d-flex align-items-center">
                                <span className="form-label">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageVisitToRentLbl"] : "Visit (to Rent)"}</span>
                            </div>
                            <div className="col-sm-3">
                                <select className={`form-select ${isRentVisitFreeSlotEmpty}`} id="visitToRentFreeSlot" value={rentVisitFreeSlot}
                                    onChange={handlerVisitToRentFreeSlot}>
                                    <option value="">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    {
                                        calendarActions.length > 0 ? calendarActions.map((item, index) => {
                                            return (
                                                <option value={item.id} key={index}>{item.name}</option>
                                            )
                                        }) :
                                            <option>{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    }
                                </select>
                            </div>
                            <div className="col-sm-3">
                                <select className={`form-select ${isRentVisitBeforeEmpty}`} id="visitToRentBefore" value={rentVisitBefore}
                                    onChange={handlerVisitToRentBefore}>
                                    <option value="">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    {
                                        calendarActions.length > 0 ? calendarActions.map((item, index) => {
                                            return (
                                                <option value={item.id} key={index}>{item.name}</option>
                                            )
                                        }) :
                                            <option>{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    }
                                </select>
                            </div>
                            <div className="col-sm-3">
                                <select className={`form-select ${isRentVisitAfterEmpty}`} id="visitToRentAfter" value={rentVisitAfter}
                                    onChange={handlerVisitToRentAfter}>
                                    <option value="">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    {
                                        calendarActions.length > 0 ? calendarActions.map((item, index) => {
                                            return (
                                                <option value={item.id} key={index}>{item.name}</option>
                                            )
                                        }) :
                                            <option>{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSelectAction"] : "Select Action"}</option>
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <span className="form-title">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageEmailLbl"] : "Emails"}</span>
                    <div className="form-container mt-1 mb-4">
                        <div className="form-group row align-items-center">
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="officeName" className="form-group-label me-2 my-0">
                                    {reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageDeactivateEmailLbl"] : "Deactivate the 'accepted visit confirmation' email"}
                                </label>
                            </div>
                            <div className="col-sm-12 col-md-3 d-flex">
                                <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-50 line-height-0 me-2" htmlFor={`radioradioYes`}>
                                    <input className="custom-radio me-2" type="radio" name={`radioDisableAcceptEmail`} id={`radioYes`} checked={isAcceptenceEmailDisabled === true} onChange={(e) => setIsAcceptenceEmailDisabled(true)} />
                                    {reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageYesRadioLbl"] : "Yes"}
                                </label>
                                <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-50 line-height-0" htmlFor={`radioNo`}>
                                    <input className="custom-radio me-2" type="radio" name={`radioDisableAcceptEmail`} id={`radioNo`} checked={isAcceptenceEmailDisabled === false} onChange={(e) => setIsFreeSlotWhiseSyncEnabled(false)} />
                                    {reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageNoRadioLbl"] : "No"}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <span className="form-title">Configurations</span>
                    <div className="form-container mt-1 mb-4">
                        <div className="form-group row align-items-center">
                            <div className="col-sm-12 col-md-12 d-flex">
                                <label className={`modal-p-text-black d-flex align-items-center`} htmlFor="checkboxSynchronization">
                                    <input className="form-check-input checkbox-green me-2 mt-0" type="checkbox" checked={isFreeSlotWhiseSyncEnabled} id="checkboxSynchronization" onChange={(e) => setIsFreeSlotWhiseSyncEnabled(previous => !previous)} />
                                    Enable Free Slots Synchronization on Whise
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <span className="form-title">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageAgencyDetailsLbl"] : "Agency Details"}</span>
                    <div className="form-container mt-1">
                        <div className="form-group row align-items-center pt-2">
                            <label htmlFor="officeName" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageOfficeNameLbl"] : "Office Name"}</label>
                            <div className="col-sm-5">
                                <input type="text" className="form-control" id="officeName" defaultValue={office.name} />
                            </div>
                            <label htmlFor="officeId" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageOfficeIdLbl"] : "Office ID"}</label>
                            <div className="col-sm-5">
                                <input type="text" className="form-control" id="officeId" defaultValue={office.id} />
                            </div>
                        </div>
                        <div className="form-group row align-items-center pt-4">
                            <label htmlFor="street" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageStreetLbl"] : "Street"}</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="street" defaultValue={office.address1} />
                            </div>
                            <label htmlFor="number" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageNumberLbl"] : "Number"}</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="number" defaultValue={office.number} />
                            </div>
                            <label htmlFor="box" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageBoxLbl"] : "Box"}</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" id="box" defaultValue={office.box} />
                            </div>
                        </div>
                        <div className="form-group row align-items-center pt-4">
                            <label htmlFor="zip" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageZipLbl"] : "Zip"}</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="zip" defaultValue={office.zip} />
                            </div>
                            <label htmlFor="city" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageCityLbl"] : "City"}</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" id="city" defaultValue={office.city} />
                            </div>
                            <label htmlFor="country" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageCountryLbl"] : "Country"}</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="country" defaultValue={office.countryId} />
                            </div>
                        </div>
                        <div className="form-group row align-items-center pt-4">
                            <label htmlFor="officeTel" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageOfficeTelLbl"] : "Office tel"}</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="officeTel" defaultValue={office.telephone} />
                            </div>
                            <label htmlFor="officeMobile" className="col-sm-2 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageOfficeMobileLbl"] : "Office mobile number"}</label>
                            <div className="col-sm-5">
                                <input type="text" className="form-control" id="officeMobile" defaultValue={office.mobile} />
                            </div>
                        </div>
                        <div className="form-group row align-items-center pt-4">
                            <label htmlFor="officeEmail" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageOfficeEmailsLbl"] : "Office Emails"}</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="officeEmail" defaultValue={office.email} />
                            </div>
                            <label htmlFor="officeWebsite" className="col-sm-2 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageOfficeWebsiteLbl"] : "Office Website"}</label>
                            <div className="col-sm-5">
                                <input type="text" className="form-control" id="officeWebsite" defaultValue={office.website} />
                            </div>
                        </div>
                        <div className="form-group row align-items-center pt-4">
                            <label htmlFor="urlLink" className="col-sm-1 form-group-label my-0">{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageUrllinkLbl"] : "Or URL link"}</label>
                            <div className="col-sm-6">
                                <input type="text" className="form-control" id="urlLink" defaultValue={clientSettings != null && clientSettings.logoUrl != undefined ? clientSettings.logoUrl : ""} readOnly />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-4 pb-5">
                <div className="col-12 text-center">
                    <button className="site-btn" onClick={handlerResetSettings}>{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageResetBtnText"] : "Reset"}</button>
                    <button className="site-btn ms-2" onClick={handlerSaveSetting}>{reduxLanguageTranslations ? reduxLanguageTranslations["settingsPageSaveBtnText"] : "Save"}</button>
                </div>
            </div>
        </div>
    )
}