import * as React from "react";
import axios from 'axios';
import { useSelector, useStore } from "react-redux";

import plusBlueIcon from '../assets/images/plus_blue.svg';
import closeIcon from '../assets/images/cross-close.svg';
import warning from '../assets/images/warning.svg';
import lock from '../assets/images/lock.svg';
import checklist from '../assets/images/Checklist.svg';
import { variables } from '../Variables';

const { useState, useEffect } = React;

export const SetupInvitee = ({ handleClose, callBackClickHandlerNext, backButtonClickHandler }) => {
    const status = useSelector(state => state.statusFilter.statusFilter);
    const agent = useSelector(state => state.agentFilter.agentFilter);
    const reduxEstate = useSelector(state => state.selectedProperty.selectedProperty);
    const user = useSelector(state => state.user.user);

    const [isEstateSetupSaved, setIsEstateSetupSaved] = useState(false);
    const [enableBookingFormSetup, setEnableBookingFormSetup] = useState(false);

    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);

    const onChangeHandleQuestionCheckbox = (e) => {
        setEnableBookingFormSetup(e.target.checked);
    }

    const clickHandlerNext = async () => {
        let isQuestionsSaved = false;
        let isDocTypeSavedInConfig = false;
        if (reduxEstate) {
            const jsonconfig = {
                headers: {
                    'Authorization': `Bearer ${user.tokenValue}`,
                    'Content-Type': 'application/json'
                }
            };
            const url = variables.API_URL + `Calendar/GetEstateConfigurations?clientId=${user.user.clientId}&estateId=${reduxEstate.id}`;
            await axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
                .then(response => {
                    let _estateConfig = response.data;
                    if (_estateConfig != null && JSON.parse(response.data.configurationDetail).questions && JSON.parse(response.data.configurationDetail).questions.length > 0) {
                        isQuestionsSaved = true;
                    }
                    if (_estateConfig != null && JSON.parse(response.data.configurationDetail).requiredDocuments && JSON.parse(response.data.configurationDetail).requiredDocuments.length > 0) {
                        isDocTypeSavedInConfig = true;
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
        let name = true;
        let firstName = true;
        let email = true;
        let mobile = true;
        let question = document.querySelector("#questionCheckbox").checked;
        let docs = document.querySelector("#documentsCheckbox").checked;
        let agentRecap = document.querySelector("#agentRecapCheckbox").checked;

        if (name == "" || firstName == "" || email == "" || mobile == "") {
            alert("please fill the required fields");
            return;
        }

        let objInvitee = {
            FirstName: firstName,
            Name: name,
            Email: email,
            Mobile: mobile,
            Question: question,
            Documents: docs,
            AgentOnSiteRecap: agentRecap,
        }
        callBackClickHandlerNext(objInvitee, isQuestionsSaved, isDocTypeSavedInConfig);
    }

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <ul className="form-steps">
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar active"></li>
                    <li className="step-bar"></li>
                </ul>
                <img src={closeIcon} onClick={handleClose} className="cursor-pointer" />
            </div>
            <div>
                <p className="form-title mb-2">{reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalTitle"] : "New Event" }</p>
            </div>
            <div className="warning mb-2">
                <img src={warning} className="me-3" />
                <p className="mb-0">
                    {reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalFutureEventText"] : "Only future events will be catered."}
                </p>
            </div>
            <div>
                <p className="subtitle mb-4">{reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalSubTitle"] : "Setup Invitee"}</p>
            </div>
            <div className="warning mb-4">
                <img src={warning} className="me-3" />
                <p className="mb-0">
                    {reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalconfigurationText"] :
                        "La configuration du formulaire de réservation de visite est défini par bien. Si vous ajouter de nouvelle disponibilité demain pour ce bien, vous n'aurez plus ce choix à définir. Pour le changer, veuillez aller dans les setups de la fiche bien."
                    }                
                </p>
            </div>
            <div className="mb-4">
                <div className="mb-4 d-flex align-items-center">
                    <img src={lock} className="me-3" />
                    <input type="text" id="name" className="form-control w-100" placeholder={reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalNamePlaceHolder"] : "Name"} readOnly={true} />
                </div>
                <div className="mb-4 d-flex align-items-center">
                    <img src={lock} className="me-3" />
                    <input type="text" id="firstName" className="form-control w-100" placeholder={reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalFirstNamePlaceHolder"] : "Frist name"} readOnly={true} />
                </div>
                <div className="mb-4 d-flex align-items-center">
                    <img src={lock} className="me-3" />
                    <input type="text" id="email" className="form-control w-100" placeholder={reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalEmailPlaceHolder"] : "Email"} readOnly={true} />
                </div>
                <div className="mb-4 d-flex align-items-center">
                    <img src={lock} className="me-3" />
                    <input type="text" id="mobile" className="form-control w-100" placeholder={reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalMobilePlaceHolder"] : "Mobile"} readOnly={true} />
                </div>
                <div className="form-check mb-4 d-flex align-items-center">
                    <input className="form-check-input checkbox-green me-3 mt-0" type="checkbox" value="" id="questionCheckbox" onChange={onChangeHandleQuestionCheckbox} />
                    <input type="text" id="question" className="form-control w-100" placeholder={reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalQuestionPlaceHolder"] : "Question"} readOnly={true} />
                </div>
                <div className="form-check mb-4 d-flex align-items-center">
                    <input className="form-check-input checkbox-green me-3 mt-0" type="checkbox" value="" id="documentsCheckbox" />
                    <input type="text" id="documents" className="form-control w-100" placeholder={reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalDocumentsPlaceHolder"] : "Documents"} readOnly={true} />
                </div>
                <div className="form-check mb-4 d-flex align-items-center">
                    <input className="form-check-input checkbox-green me-3 mt-0" type="checkbox" value="" id="agentRecapCheckbox" />
                    <input type="text" id="agentRecap" className="form-control w-100" placeholder={reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalRecapAgentPlaceHolder"] : "Recap of the agent on site"} readOnly={true} />
                </div>
            </div>
            <div className="d-flex">
                <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-100 me-3" onClick={backButtonClickHandler}>{reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalBackBtn"] : "Back"}</button>
                <button type="button" className="site-btn site-btn-padd w-100" onClick={clickHandlerNext}>{reduxLanguageTranslations ? reduxLanguageTranslations["inviteeModalNextBtn"] : "Next"}</button>
            </div>
        </>
    )
}