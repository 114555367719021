import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from 'axios';
import { variables } from "../Variables";

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import calLoader from '../assets/images/calendar-loader.svg';

import {
    GridRowModes,
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { nlNL, frFR } from '@mui/x-data-grid/locales';

import Box from '@mui/material/Box';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import languageDropdownIcon from "../assets/images/language-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { setlanguagetranslations} from "../features/SelectedLanguageTranslationsSlice";

const initialRows = [
    {
        id: 0,
        enGB: "Book a visit",
        frBE: "Réserver une visite",
        nlBE: "Boek een bezoek",
        siteLabel: "bookingWindowTitle"
    },
    {
        id: 1,
        enGB: "Available slots",
        frBE: "Emplacements disponibles",
        nlBE: "Beschikbare slots",
        siteLabel: "bookingAvailableSlotsTitle"
    },
    {
        id: 2,
        enGB: "Pick a slot that will suits you on",
        frBE: "Choisissez un créneau qui vous convient",
        nlBE: "Kies een slot dat bij je past",
        siteLabel: "slotBookingAnnouncementSuitableDateTimeLabel"
    },
    {
        id: 3,
        enGB: "Book a Slot",
        frBE: "Réservez un créneau",
        nlBE: "Boek een slot",
        siteLabel: "slotBookingBtn"
    },
    {
        id: 4,
        enGB: "Booking",
        frBE: "Réservation",
        nlBE: "Boeking",
        siteLabel: "slotBookingContactModalTitle"
    },
];

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'rgba(18, 18, 18, 0.9)',
    ...theme.applyStyles('light', {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
    }),
}));

const CustomLoadingOverlay = () => {
    return (
        <StyledGridOverlay>
            <img src={calLoader} className="cal-loader" />
        </StyledGridOverlay>
    );
}

export const Translations = () => {
    const [translationsForAllLanguages, setTranslationsForAllLanguages] = useState(null);
    const [allLanguagesForDropdown, setAllLanguageForDropdown] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [activeModal, setActiveModal] = useState("");
    const [selectedLanguageDropdown, setsSelectedLanguageDropdown] = useState(null);
    const [translationGridData, setTranslationGridData] = useState([]);
    const [uniqueLanguages, setUniqueLanguages] = useState([]);
    const [gridRows, setGridRows] = useState(initialRows);
    const [gridCols, setGridCols] = useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [isGridLoading, setIsGridLoading] = useState(true);
    const [gridTranslationsLocaleText, setGridTranslationsLocaleText] = useState(null);

    const user = useSelector(state => state.user.user);
    const [userId, setUserId] = useState(user.user.userId);
    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);
    const reduxSelectedLanguage = useSelector(state => state.selectedLanguageTranslations.selectedLanguage);

    const dispatch = useDispatch();

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const addNewLanguage = () => {
        setActiveModal("addLanguage");
        setShowModal(true);
    }

    const editLanguage = () => {
        setActiveModal("editLanguage");
        setShowModal(true);
    }

    const getAllLanguage = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetAllLanguages`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let allLanguage = response.data;
                setAllLanguageForDropdown(allLanguage);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        getAllLanguage();
    }, []);

    const getTranslationsGridData = () => {

        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };

        const url = variables.API_URL + `Settings/GetTranslationsGridData`;

        axios.post(url, {}, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let allTranslations = response.data;
                setTranslationGridData(allTranslations);
                setIsGridLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (translationGridData.length > 0) {
            const _uniqueLanguages = Array.from(
                new Set(translationGridData.flatMap((item) => item.translations.map((t) => t.languageCode)))
            );

            setUniqueLanguages(_uniqueLanguages);
        }
    }, [translationGridData])

    useEffect(() => {
        if (uniqueLanguages && translationGridData.length > 0 && reduxLanguageTranslations) {
            const columns = [
                { field: 'siteLabel', headerName: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridSiteLabelHeading"] : 'siteLabel', flex: 1, editable: false },
                ...uniqueLanguages.map((languageCode) => ({
                    field: languageCode,
                    headerName: `${reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridTranslationLabelHeading"] : "Translation"} (${languageCode})`,
                    flex: 1,
                    editable: true,
                })),
                {
                    field: 'labelScope',
                    headerName: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridLabelScopeHeading"] : 'Label Scope',
                    flex: 1,
                    editable: false
                },
                {
                    field: 'actions',
                    type: 'actions',
                    headerName: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridActionsLabelHeading"] : 'Actions',
                    flex: 1,
                    cellClassName: 'actions',
                    getActions: ({ id }) => {
                        //alert("getActions");
                        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                        if (isInEditMode) {
                            return [
                                <GridActionsCellItem
                                    icon={<SaveIcon />}
                                    label="Save"
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                    onClick={handleSaveClick(id)}
                                />,
                                <GridActionsCellItem
                                    icon={<CancelIcon />}
                                    label="Cancel"
                                    className="textPrimary"
                                    onClick={handleCancelClick(id)}
                                    color="inherit"
                                />,
                            ];
                        }
                        else {
                            return [
                                <GridActionsCellItem
                                    icon={<EditIcon />}
                                    label="Edit"
                                    className="textPrimary"
                                    onClick={handleEditClick(id)}
                                    color="inherit"
                                />,
                            ];
                        }
                    },
                }
            ];

            setGridCols(columns);

            const rows = translationGridData.map((item, index) => {
                const row = {
                    id: index, // Unique ID for each row
                    siteLabel: item.siteLabel,
                    labelScope: item.labelScope,
                    //isEditing: false,
                    originalItem: item
                };
                // Add translations dynamically based on language code
                item.translations.forEach((translation) => {
                    row[translation.languageCode] = translation.labelTranslation;
                });
                return row;
            });

            setGridRows(rows);
        }
    }, [uniqueLanguages, rowModesModel, reduxLanguageTranslations])

    const getLanguageTranslations = () => {
        const jsonconfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetTranslationsForSiteByLang?siteName=agent`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let translations = response.data;
                if (Object.keys(translations).length > 0) {
                    setTranslationsForAllLanguages(translations);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (reduxSelectedLanguage != null && translationsForAllLanguages != null) {
            dispatch(setlanguagetranslations(translationsForAllLanguages[reduxSelectedLanguage]));
        }
    }, [reduxSelectedLanguage, translationsForAllLanguages])

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        if (Object.keys(rowModesModel).length == 0) {
            setRowModesModel({ [id]: { mode: GridRowModes.Edit } });
        }
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ [id]: { mode: GridRowModes.View } });     
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({ [id]: { mode: GridRowModes.View, ignoreModifications: true } });

        const editedRow = gridRows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setGridRows(gridRows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        setIsGridLoading(true);
        newRow.originalItem.translations = newRow.originalItem.translations.map(translation => {
            const updatedValue = newRow[translation.languageCode]; // Get the updated value from the main object
            // Update labelTranslation if the updated value exists
            translation.labelTranslation = updatedValue;
            return translation;
        });
        updateTranslation(newRow.originalItem);
        return newRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const updateTranslation = (translation) => {

        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };

        const url = variables.API_URL + `Settings/UpdateTranslation`;

        axios.post(url, JSON.stringify(translation), jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                getTranslationsGridData();
                getLanguageTranslations();
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (userId != null) {
            getTranslationsGridData();
        }
    }, [userId]);

    useEffect(() => {
        if (reduxLanguageTranslations) {
            let gridTranslationObj = {
                // Column menu text
                columnMenuUnsort: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridColumnMenuUnsort"] : "columnMenuUnsort",
                columnMenuSortAsc: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridColumnMenuSortAsc"] : "columnMenuSortAsc",
                columnMenuSortDesc: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridColumnMenuSortDesc"] : "columnMenuSortDesc",
                columnMenuFilter: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridColumnMenuFilter"] : "columnMenuFilter",
                columnMenuHideColumn: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridColumnMenuHideColumn"] : "columnMenuHideColumn",
                columnMenuShowColumns: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridColumnMenuShowColumns"] : "columnMenuShowColumns",
                columnMenuLabel: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridColumnMenuLabel"] : "Menu",
                columnHeaderSortIconLabel: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridColumnHeaderSortIconLabel"] : "Sort",

                // Filter panel text
                filterPanelDeleteIconLabel: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterPanelDeleteIconLabel"] : "Delete",
                filterPanelOperator: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterPanelOperator"] : "Operator",
                filterPanelColumns: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterPanelColumns"] : "Columns",
                filterPanelInputLabel: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterPanelInputLabel"] : "Value",
                filterPanelInputPlaceholder: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterPanelInputPlaceholder"] : "Filter value",

                // Filter operators text
                filterOperatorContains: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterOperatorContains"] : "contains",
                filterOperatorDoesNotContain: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterOperatorDoesNotContain"] : "does not contain",
                filterOperatorEquals: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterOperatorEquals"] : "equals",
                filterOperatorDoesNotEqual: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterOperatorDoesNotEqual"] : "does not equal",
                filterOperatorStartsWith: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterOperatorStartsWith"] : "starts with",
                filterOperatorEndsWith: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterOperatorEndsWith"] : "ends with",
                filterOperatorIsEmpty: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterOperatorIsEmpty"] : "is empty",
                filterOperatorIsNotEmpty: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterOperatorIsNotEmpty"] : "is not empty",
                filterOperatorIsAnyOf: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridFilterOperatorIsAnyOf"] : "is any of",

                // Used table pagination translation keys
                MuiTablePagination: {
                    getItemAriaLabel: (type) => {
                        if (type === 'first') {
                            return reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridGoToFirstPageBtnLabel"] : "Go to first page";
                        }
                        if (type === 'last') {
                            return reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridGoToLastPageBtnLabel"] : "Go to last page";
                        }
                        if (type === 'next') {
                            return reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridGoToNextPageBtnLabel"] : "Go to next page";
                        }
                        return reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridGoToPreviousPageBtnLabel"] : "Go to previous page";
                    },
                    labelRowsPerPage: reduxLanguageTranslations != null ? reduxLanguageTranslations["muiDataGridLabelRowsPerPage"] : "Rows per page:",
                }
            }
            setGridTranslationsLocaleText(gridTranslationObj);
        }
    }, [reduxLanguageTranslations]);

    return (
        <>
            <div className="px-4">
                <h1 className="form-title mb-2">{reduxLanguageTranslations != null ? reduxLanguageTranslations["translationPageTitle"] : "Booking & Agent Site Translations"}</h1>
                <div className="form-container">
                    <div className="row">
                        <div className="col-sm-12">
                            <DataGrid
                                rows={gridRows}
                                columns={gridCols}
                                editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={handleRowModesModelChange}
                                onRowEditStop={handleRowEditStop}
                                processRowUpdate={processRowUpdate}
                                pagination
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 8 }, // default page size
                                    },
                                }}
                                pageSizeOptions={[8, 20, 50, 100]}
                                loading={isGridLoading}
                                slots={{
                                    loadingOverlay: CustomLoadingOverlay,
                                }}
                                localeText={gridTranslationsLocaleText}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}