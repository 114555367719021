import * as React from "react";
import * as ReactDOM from "react-dom";
import { variables } from '../Variables';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import { DateFormatter } from '@fullcalendar/core';
import { useSelector, useDispatch } from "react-redux";
import { setCalendarView } from "../features/CalendarSelectedViewSlice";
import calLoader from '../assets/images/calendar-loader.svg';

const { useState, useRef, useEffect } = React;
export const Schedular = ({ showMessageBox, selectedProperty, calendarRef, calendarEvents, tobeConfirmedIds, confirmedIds, freeSlotIds, eventClickHandler,
    selectedAgentsList, selectedStatusAction, showCalendarLoader }) => {
    const [calendarActions, setCalendarActions] = useState([]);
    const [events, setEvents] = useState([]);

    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    var daysFullname = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const whiseUserToken = useSelector(state => state.whiseUser.whiseToken);
    const actions = useSelector(state => state.calendarActions.calendarActions);
    const calendarView = useSelector(state => state.calendarView.calendarView);
    const reduxSelectedLanguage = useSelector(state => state.selectedLanguageTranslations.selectedLanguage);
    const dispatch = useDispatch();

    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);

    const getStatus = (actionId) => {
        if (tobeConfirmedIds.indexOf(actionId) !== -1) {
            return "To Be Confirmed";
        }
        else if (confirmedIds.indexOf(actionId) !== -1) {
            return "Confirmed";
        } else if (freeSlotIds.indexOf(actionId) !== -1) {
            return "Free Slot";
        } else {
            return '';
        }
    }

    const getAction = (actionId) => {
        let action = calendarActions.filter((d) => d.id === actionId);
        if (action.length > 0) {
            return action[0].name;
        }
        else {
            return "";
        }
    }

    const getBackGround = (actionId) => {
        if (tobeConfirmedIds.indexOf(actionId) !== -1) {
            return "#B0B0B0";
        }
        else if (confirmedIds.indexOf(actionId) !== -1) {
            return "#15C31C";
        }
        else if (freeSlotIds.indexOf(actionId) !== -1) {
            return "#B0B0B0";
        }
        else {
            return "";
        }
    }

    const getIsMatchedWithUser = (event) => {
        if (!event || !Array.isArray(event.users) || event.users.length === 0) {
            return false; // Return false if users array is invalid or empty
        }

        const eventUserId = event.users[0].userId;

        const userExistInEvent = selectedAgentsList.filter(item => item.value === eventUserId);

        return userExistInEvent.length > 0;
    };

    const getIsEventMatchedWithSettings = (event) => {
        if (tobeConfirmedIds.indexOf(event.action.id) !== -1 || confirmedIds.indexOf(event.action.id) !== -1 || freeSlotIds.indexOf(event.action.id) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    const handlerOnChangeCalendarView = (view) => {
        let viewType = view.view.type
        dispatch(setCalendarView(viewType));
    }

    useEffect(() => {
        if (
            calendarEvents &&
            calendarEvents.length > 0 &&
            calendarActions.length > 0 &&
            selectedProperty
        ) {
            // Memoize status dropdown values
            const statusDropdownValues = selectedStatusAction.reduce((acc, item) => {
                if (item._freeSlotIds) acc.push(...item._freeSlotIds);
                if (item.beforeConfirmationIds) acc.push(...item.beforeConfirmationIds);
                if (item.afterConfirmationIds) acc.push(...item.afterConfirmationIds);
                return acc;
            }, []);

            const eventsList = calendarEvents.reduce((acc, item) => {
                const estateId = item.estates?.[0]?.estateId || 0;
                const isEventMappedWithSettings = getIsEventMatchedWithSettings(item);
                const isEventMappedWithUser = selectedAgentsList.length > 0 && getIsMatchedWithUser(item);
                const isMatchedWithEstate = estateId === selectedProperty.id;

                if (estateId == 6376064) {
                    console.log(estateId);
                }

                // Create event object
                const eventObject = {
                    id: item.id,
                    title: item.subject,
                    start: item.startDateTime,
                    end: item.endDateTime,
                    backgroundColor: "rgb(243 243 243)",
                    extendedProps: {
                        status: getStatus(item.action.id),
                        eventAction: getAction(item.action.id),
                        background: getBackGround(item.action.id),
                        fullName: item.users?.[0]?.name || "", // Safely access item.users[0].name
                        firstLetter: item.users?.[0]?.name?.charAt(0) || "", // Safely access item.users[0].name.charAt(0)
                        isMatched: false,
                        action: item.action.id,
                        estateId,
                        italic: false,
                        eventId: item.id,
                        users: item.users || [],
                        estates: item.estates || [],
                        contacts: item.contacts || [],
                        startDateTime: item.startDateTime,
                        endDateTime: item.endDateTime,
                        isLocalDbEvent: !!item.isLocalDbEvent,
                    },
                };

                // Determine matching criteria
                const addToList = () => {
                    eventObject.extendedProps.isMatched = true;
                    eventObject.backgroundColor = "#ECF1FF";
                    acc.push(eventObject);
                };

                if (estateId !== 0) {
                    if (isEventMappedWithUser && isEventMappedWithSettings && isMatchedWithEstate) {
                        if (
                            statusDropdownValues.length === 0 ||
                            statusDropdownValues.includes(item.action.id)
                        ) {
                            addToList();
                        }
                    } else if (!isEventMappedWithUser && isEventMappedWithSettings && isMatchedWithEstate) {
                        addToList();
                    } else if (
                        (isEventMappedWithUser && isMatchedWithEstate) ||
                        (isEventMappedWithUser && !isMatchedWithEstate) ||
                        (!isEventMappedWithUser && !isMatchedWithEstate)
                    ) {
                        acc.push(eventObject);
                    }
                } else if (isEventMappedWithUser || isEventMappedWithSettings) {
                    acc.push(eventObject);
                }

                return acc;
            }, []);

            setEvents(eventsList);
        } else {
            setEvents([]);
        }
    }, [calendarEvents, calendarActions, selectedStatusAction, selectedAgentsList, selectedProperty]);

    //useEffect(() => {
    //    if (calendarEvents != undefined && calendarEvents.length > 0 && calendarActions.length > 0 && selectedProperty != null) {
    //        let statusDropdownValues = [];
    //        if (selectedStatusAction.length > 0) {
    //            selectedStatusAction.forEach(item => {
    //                if (item._freeSlotIds != undefined) {
    //                    statusDropdownValues.push.apply(statusDropdownValues, item._freeSlotIds)
    //                }
    //                if (item.beforeConfirmationIds != undefined) {
    //                    statusDropdownValues.push.apply(statusDropdownValues, item.beforeConfirmationIds)
    //                }
    //                if (item.afterConfirmationIds != undefined) {
    //                    statusDropdownValues.push.apply(statusDropdownValues, item.afterConfirmationIds)
    //                }
    //            })
    //        }
    //        let eventsList = [];
    //        //let count = 0;
    //        if (selectedAgentsList.length > 0) {
    //            calendarEvents.forEach(item => {
    //                let isEventMappedWithSettings = getIsEventMatchedWithSettings(item);
    //                let isEventMappedWithUser = getIsMatchedWithUser(item);
    //                let estateId = item.estates != undefined ? item.estates[0].estateId : 0;
    //                //if (estateId == "6313597" && item.subject == "FREE SLOT: Rue de Serbie  5/7, 1060 Saint-Gilles" && item.action.id == "146008") {
    //                //    console.log(estateId);
    //                //}
    //                let isMatchedWithEstate = estateId == selectedProperty.id ? true : false;

    //                let objectToPush = {
    //                    id: item.id, title: item.subject, start: item.startDateTime, end: item.endDateTime,
    //                    backgroundColor: "rgb(243 243 243)",
    //                    extendedProps: {
    //                        status: getStatus(item.action.id),
    //                        eventAction: getAction(item.action.id),
    //                        background: getBackGround(item.action.id),
    //                        fullName: item.users[0].name,
    //                        firstLetter: item.users[0].name.split("")[0],
    //                        isMatched: false,
    //                        action: item.action.id,
    //                        estateId: item.estates != undefined ? item.estates[0].estateId : 0,
    //                        italic: false,
    //                        eventId: item.id,
    //                        users: item.users != undefined ? item.users : [],
    //                        estates: item.estates != undefined ? item.estates : [],
    //                        contacts: item.contacts != undefined ? item.contacts : [],
    //                        startDateTime: item.startDateTime,
    //                        endDateTime: item.endDateTime,
    //                        isLocalDbEvent: item.isLocalDbEvent ? item.isLocalDbEvent : false
    //                    }
    //                }
    //                if (estateId != 0) {
    //                    if (isEventMappedWithUser == true && isEventMappedWithSettings == true && isMatchedWithEstate == true) {
    //                        if (statusDropdownValues.length == 0) {
    //                            objectToPush.extendedProps.isMatched = true;
    //                            objectToPush.backgroundColor = "#ECF1FF";
    //                            eventsList.push(objectToPush);
    //                        }
    //                        else if (statusDropdownValues.length > 0 && statusDropdownValues.indexOf(item.action.id) != -1) {
    //                            objectToPush.extendedProps.isMatched = true;
    //                            objectToPush.backgroundColor = "#ECF1FF";
    //                            eventsList.push(objectToPush);
    //                        }
    //                    }
    //                    else if (isEventMappedWithUser == false && isEventMappedWithSettings == true && isMatchedWithEstate == true) {
    //                        objectToPush.extendedProps.isMatched = true;
    //                        objectToPush.backgroundColor = "#ECF1FF";
    //                        eventsList.push(objectToPush);
    //                    }
    //                    else if (isEventMappedWithUser == true && isMatchedWithEstate == true && isEventMappedWithSettings == false) {
    //                        eventsList.push(objectToPush);
    //                    }
    //                    else if (isEventMappedWithUser == true && isEventMappedWithSettings == true && isMatchedWithEstate == false) {
    //                        eventsList.push(objectToPush);
    //                    }
    //                    else if (isEventMappedWithUser == true && isEventMappedWithSettings == false && isMatchedWithEstate == false) {
    //                        eventsList.push(objectToPush);
    //                    }
    //                }
    //                else {
    //                    if (isEventMappedWithUser == true || isEventMappedWithSettings == true) {
    //                        eventsList.push(objectToPush)
    //                    }
    //                }
    //            })
    //        }
    //        else {
    //            calendarEvents.forEach(item => {
    //                let isEventMappedWithSettings = getIsEventMatchedWithSettings(item);
    //                let estateId = item.estates != undefined ? item.estates[0].estateId : 0;
    //                //if (estateId == "6313597" && item.subject == "FREE SLOT: Rue de Serbie  5/7, 1060 Saint-Gilles" && item.action.id == 146008) {
    //                //    console.log(estateId);
    //                //}
    //                let objectToPush = {
    //                    id: item.id, title: item.subject, start: item.startDateTime, end: item.endDateTime,
    //                    backgroundColor: "rgb(243 243 243)",
    //                    extendedProps: {
    //                        status: getStatus(item.action.id),
    //                        eventAction: getAction(item.action.id),
    //                        background: getBackGround(item.action.id),
    //                        fullName: item.users[0].name,
    //                        firstLetter: item.users[0].name.split("")[0],
    //                        isMatched: false,
    //                        action: item.action.id,
    //                        estateId: item.estates != undefined ? item.estates[0].estateId : 0,
    //                        italic: false,
    //                        eventId: item.id,
    //                        users: item.users != undefined ? item.users : [],
    //                        estates: item.estates != undefined ? item.estates : [],
    //                        contacts: item.contacts != undefined ? item.contacts : [],
    //                        startDateTime: item.startDateTime,
    //                        endDateTime: item.endDateTime,
    //                        isLocalDbEvent: item.isLocalDbEvent ? item.isLocalDbEvent : false
    //                    }
    //                }

    //                if (isEventMappedWithSettings == true && estateId != 0 && estateId == selectedProperty.id && statusDropdownValues.length == 0) {
    //                    objectToPush.extendedProps.isMatched = true;
    //                    objectToPush.backgroundColor = "#ECF1FF";
    //                    eventsList.push(objectToPush);
    //                }
    //                else if (isEventMappedWithSettings == true && estateId != 0 && estateId == selectedProperty.id && statusDropdownValues.length > 0 && statusDropdownValues.indexOf(item.action.id) != -1) {
    //                    objectToPush.extendedProps.isMatched = true;
    //                    objectToPush.backgroundColor = "#ECF1FF";
    //                    eventsList.push(objectToPush);
    //                }
    //            })
    //        }
    //        setEvents(eventsList);
    //    } else {
    //        setEvents([])
    //    }
    //}, [calendarEvents, calendarActions, selectedStatusAction])

    useEffect(() => {
        if (actions != null && actions != undefined) {
            setCalendarActions(actions);
        }
    }, [actions])

    //useEffect(() => {
    //    if (calendarView == null) {
    //        dispatch(setCalendarView("timeGridDay"));
    //    }
    //}, [calendarView])

    return (
        <>
            <div className="form-container position-relative">
                <div className="schedular-container" id="schedularContainer">
                    {
                        showCalendarLoader == false ?
                            <>
                                <FullCalendar
                                    ref={calendarRef}
                                    editable
                                    selectable
                                    events={events}
                                    firstDay={1}
                                    locales={allLocales}
                                    locale={reduxSelectedLanguage ? reduxSelectedLanguage : 'en-GB'}
                                    //dayHeaderContent={(args) => {
                                    //    if (calendarView == 'timeGridWeek') {
                                    //        let date = args.date;
                                    //        let day = days[date.getDay()];
                                    //        let monthDate = (date.getDate()) < 10 ? "0" + (date.getDate()) : (date.getDate());
                                    //        let month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
                                    //        return day + " " + monthDate + "/" + month;
                                    //    }
                                    //    if (calendarView == 'timeGridDay') {
                                    //        let date = args.date;
                                    //        let day = daysFullname[date.getDay()];
                                    //        return day;
                                    //    }
                                    //    if (calendarView == 'dayGridMonth') {
                                    //        let date = args.date;
                                    //        let day = days[date.getDay()];
                                    //        return day;
                                    //    }
                                    //}}
                                    columnFormat={
                                        {
                                            week: 'ddd D/M',
                                        }
                                    }
                                    eventContent={(data) => {
                                        let startDate = data.event.start;
                                        let endDate = data.event.end || startDate;;
                                        let startTime = startDate.getTime();
                                        let endTime = endDate.getTime();

                                        var diff = (startTime - endTime) / 1000;
                                        diff /= 60;

                                        let timeDifference = Math.abs(Math.round(diff));

                                        let action = data.event._def.extendedProps.eventAction;

                                        if (data.event._def.extendedProps.status == "Free Slot" || data.event._def.extendedProps.status == "To Be Confirmed" || data.event._def.extendedProps.status == "Confirmed") {
                                            action = reduxLanguageTranslations ? reduxLanguageTranslations["calendarPageEventStatusVisitLbl"] : "Visit";
                                        }

                                        let eventStatus = "";
                                        if (data.event._def.extendedProps.status == "Free Slot") {
                                            eventStatus = reduxLanguageTranslations ? reduxLanguageTranslations["calendarPageFilterDropdownStatusOptionFreeSl"] : "Free Slot";
                                        } else if (data.event._def.extendedProps.status == "Confirmed") {
                                            eventStatus = reduxLanguageTranslations ? reduxLanguageTranslations["calendarPageFilterDropdownStatusOptionConfirmed"] : "Confirmed";
                                        } else {
                                            eventStatus = reduxLanguageTranslations ? reduxLanguageTranslations["calendarPageFilterDropdownStatusOptionToBeConfirm"] : "To Be Confirmed";
                                        }

                                        if (endTime > startTime && (timeDifference == 0 || timeDifference > 30)) {
                                            return <div className={`event-container`} id={data.event._def.extendedProps.eventId} estateid={data.event._def.extendedProps.estateId}>
                                                <div className="d-flex justify-content-between align-items-center mb-1">
                                                    <span className={`event-action ${data.event._def.extendedProps.isMatched == true ? "blue" : "grey"} ${data.event._def.extendedProps.italic == true ? "italic" : ""}`}>{action}</span>
                                                    <span className={`rounded-circle ${data.event._def.extendedProps.isMatched == true ? "" : "grey-bg"}`} title={data.event._def.extendedProps.fullName}>{data.event._def.extendedProps.firstLetter}</span>
                                                </div>
                                                {
                                                    eventStatus != '' ?
                                                        <div className='status text-white'
                                                            style={{ background: data.event._def.extendedProps.background }} >
                                                            {eventStatus}
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        }
                                        else {
                                            let classess = "align-items-center";
                                            let circleClasses = "rounded-circle";
                                            if (timeDifference <= 30 & timeDifference >= 25) {
                                                classess = "line-height-11 align-items-center pt-0-55"
                                            }
                                            else if (timeDifference < 25 & timeDifference >= 20) {
                                                classess = "line-height-11 align-items-center pt-0-05"
                                            }
                                            else if (timeDifference < 20 & timeDifference >= 15) {
                                                classess = "line-height-11 align-items-top pt-0-05";
                                                circleClasses = "rounded-circle m-top-neg-3";
                                            }
                                            else if (timeDifference < 15 & timeDifference >= 10) {
                                                classess = "line-height-8 align-items-top pt-0";
                                                circleClasses = "rounded-circle-small";
                                            }

                                            return <div className={`event-container d-flex justify-content-between ${classess}`} id={data.event._def.extendedProps.eventId} estateid={data.event._def.extendedProps.estateId}>
                                                <span className={`event-action ${data.event._def.extendedProps.isMatched == true ? "blue" : "grey"} ${data.event._def.extendedProps.italic == true ? "italic" : ""}`}>{action}</span>
                                                <p className="d-flex mb-0">
                                                    {
                                                        data.event._def.extendedProps.status != '' ?
                                                            <span className={`${circleClasses}`} style={{ background: data.event._def.extendedProps.background }} title={eventStatus}>
                                                                {
                                                                    eventStatus.split("")[0]
                                                                }
                                                            </span>
                                                            : null
                                                    }
                                                    <span className={`${circleClasses} ${data.event._def.extendedProps.isMatched == true ? "" : "grey-bg"}`} title={data.event._def.extendedProps.fullName}>{data.event._def.extendedProps.firstLetter}</span>
                                                </p>
                                            </div>
                                        }
                                    }}
                                    headerToolbar={{
                                        start: "today prev next",
                                        center: "title",
                                        end: "timeGridDay timeGridWeek dayGridMonth"
                                    }}
                                    slotEventOverlap={false}
                                    plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                                    //views={["timeGridDay", "timeGridWeek", "dayGridMonth"]}
                                    views={{
                                        dayGridMonth: {
                                            titleFormat: { year: 'numeric', month: 'long' }  // E.g., "September 2024"
                                        },
                                        timeGridWeek: {
                                            titleFormat: { year: 'numeric', month: 'short', day: '2-digit' } // E.g., "Sep 26 - Oct 2, 2024"
                                        },
                                        timeGridDay: {
                                            titleFormat: { year: 'numeric', month: 'long', day: '2-digit' }  // E.g., "September 26, 2024"
                                        }
                                    }}
                                    initialView={calendarView == null ? "timeGridWeek" : calendarView}
                                    viewClassNames={handlerOnChangeCalendarView}
                                    eventClick={eventClickHandler}
                                    businessHours={[ // specify an array instead
                                        {
                                            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                                            startTime: '08:00',
                                            endTime: '22:00'
                                        }
                                    ]}
                                    slotDuration='01:00'
                                    allDaySlot={false}
                                />
                            </>
                            :
                            <div className="calendar-loader">
                                <div className="form-container text-center">
                                    <img src={calLoader} className="cal-loader py-3" />
                                    <p className="m-0 pt-2 pb-2">
                                        {reduxLanguageTranslations ? reduxLanguageTranslations["calendarPageLoaderText"] : "One moment we preparing the overview with the visits that already exist for the agent and the property"}
                                    </p>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </>
    )
}