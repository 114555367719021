import * as React from "react";
import * as ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import AppRoutes from '../AppRoutes';
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../features/userSlice";
import { clearstatusfilter } from "../features/StatusFilterSlice";
import { clearagentfilter } from "../features/AgentFilterSlice";
import { clearselectedproperty } from "../features/SelectedPropertySlice";
import { setSidebarCollapsed, clearSidebarCollapsed } from "../features/SidebarCollapseSlice";

import logo from '../assets/images/sidebar-logo.svg';
import logoSmall from '../assets/images/sidebar-logo-collapsed.svg';
import agentBlack from '../assets/images/agents-black.svg';
import agentRed from '../assets/images/agents-red.svg';
import calendarBlack from '../assets/images/calendar-black.svg';
import calendarRed from '../assets/images/calendar-red.svg';
import propertiesBlack from '../assets/images/properties-black.svg';
import propertiesRed from '../assets/images/properties-red.svg';
import settingsBlack from '../assets/images/settings-black.svg';
import settingsRed from '../assets/images/settings-red.svg';
import chevronLeft from '../assets/images/angle-left-small.svg';
import chevronRight from '../assets/images/angle-right-small.svg';
import logOutIcon from '../assets/images/log-out.svg';
const { useState, useEffect } = React;


export const Sidebar = () => {
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();
    const location = useLocation();
    //const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const reduxIsSidebarCollapsed = useSelector(state => state.isSideBarCollapsed.sidebarCollapsed);
    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);

    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(false);

        useEffect(() => {
            const media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
            const listener = () => setMatches(media.matches);
            window.addEventListener("resize", listener);
            return () => window.removeEventListener("resize", listener);
        }, [matches, query]);
        return matches;
    };

    const handleLogout = () => {
        dispatch(clearstatusfilter());
        dispatch(clearagentfilter());
        dispatch(clearselectedproperty());
        dispatch(clearSidebarCollapsed());
        dispatch(logout());
    }

    const minimizeSidebar = () => {
        if (reduxIsSidebarCollapsed == false) {
            dispatch(setSidebarCollapsed(true));
        }
        else {
            dispatch(clearSidebarCollapsed());
        }
    }

    const createCounterElement = () => {
        return { __html: `<span class="counter">2</span>` };
    }
    let menuItems = AppRoutes;

    useEffect(() => {
        // Clear any previous timeout
        let timeoutId;

        if (user && user.expiryDateTime) {
            const currentTime = new Date().getTime();
            const expiryTime =  new Date(user.expiryDateTime).getTime();
            const timeUntilExpiry = expiryTime - currentTime;

            if (timeUntilExpiry > 0) {
                // Set a timeout to clear the store when expiry is reached
                timeoutId = setTimeout(() => {
                    handleLogout();
                    console.log('Store cleared due to expiry');
                }, timeUntilExpiry);
            } else {
                // If expiry time is in the past, clear the store immediately
                handleLogout();
            }
        }

        // Cleanup timeout if expiryDate changes or component unmounts
        return () => clearTimeout(timeoutId);
    }, [user]);

    return (
        <div className={`sidebar ${reduxIsSidebarCollapsed == true && "sidebar-collapsed"}`}>
            <button className="btn-collapse" onClick={minimizeSidebar}>
                {
                    reduxIsSidebarCollapsed == true ?
                        <img src={chevronRight} /> :
                        <img src={chevronLeft} />
                }
            </button>
            <NavLink tag={Link} to="/" className="logo-link">
                {
                    reduxIsSidebarCollapsed == true ?
                    <img src={logoSmall}></img>
                    : <img src={logo}></img>
                }
            </NavLink>
            <div className="boxicon-container">
                {menuItems.map((item, index) => {
                    if (item.path == "/booking") {
                        return;
                    }
                    else if(user && user.user.clientId != 11613 && item.path == "/translations"){ //show translations screen to only info@mindfree.be
                        return;
                    }

                    var imageName = require('../assets/images/' + item.icon + '-black.svg');
                    if (location.pathname === item.path) {
                        var imageName = require('../assets/images/' + item.icon + '-red.svg');
                    }
                    let menuItemName = "";

                    if (item.name == "Calendar") {
                        menuItemName = reduxLanguageTranslations ? reduxLanguageTranslations["navlinkCalendar"] : item.name;
                    }
                    else if (item.name == "Properties") {
                        menuItemName = reduxLanguageTranslations ? reduxLanguageTranslations["navlinkProperties"] : item.name;
                    }
                    else if (item.name == "Agents") {
                        menuItemName = reduxLanguageTranslations ? reduxLanguageTranslations["navlinkAgents"] : item.name;
                    }
                    else if (item.name == "Settings") {
                        menuItemName = reduxLanguageTranslations ? reduxLanguageTranslations["navlinkSettings"] : item.name;
                    }
                    else if (item.name == "Translations") {
                        menuItemName = reduxLanguageTranslations ? reduxLanguageTranslations["navlinkTranslations"] : item.name;
                    }

                    if (item.path == "/properties" || item.path == "/agents") {
                        return (
                            <NavLink tag={Link} to={item.path} className={`menu-link ${location.pathname === item.path && "active"}`} key={index} disabled>
                                <span className={` ${reduxIsSidebarCollapsed == false && "d-flex justify-content-between align-items-center"}`}>
                                    <span>
                                        <img src={imageName} alt={`menu-icon` + menuItemName} title={menuItemName} />
                                        <span className={`description ${reduxIsSidebarCollapsed == true && "d-none"}`}>{menuItemName}</span>
                                    </span>
                                    {
                                        item.path == "/" ?
                                            //<span className={`count-container ${reduxIsSidebarCollapsed == true && "d-none"}`} dangerouslySetInnerHTML={createCounterElement()} ></span>
                                            <span className={`count-container ${reduxIsSidebarCollapsed == true && "d-none"}`} ></span>
                                            : ""
                                    }
                                </span>
                            </NavLink>
                        );
                    }
                    else {
                        return (
                            <NavLink tag={Link} to={item.path} className={`menu-link ${location.pathname === item.path && "active"}`} key={index}>
                                <span className={` ${reduxIsSidebarCollapsed == false && "d-flex justify-content-between align-items-center"}`}>
                                    <span>
                                        <img src={imageName} alt={`menu-icon` + menuItemName} title={menuItemName} />
                                        <span className={`description ${reduxIsSidebarCollapsed == true && "d-none"}`}>{menuItemName}</span>
                                    </span>
                                    {
                                        item.path == "/" ?
                                            //<span className={`count-container ${reduxIsSidebarCollapsed == true && "d-none"}`} dangerouslySetInnerHTML={createCounterElement()} ></span>
                                            <span className={`count-container ${reduxIsSidebarCollapsed == true && "d-none"}`}></span>
                                            : ""
                                    }
                                </span>
                            </NavLink>
                        );
                    }
                })}

                <button className="menu-link nav-link logout" onClick={handleLogout}>
                    <span className={` ${reduxIsSidebarCollapsed == false && "d-flex justify-content-between"}`}>
                        <span>
                            <img src={logOutIcon} alt="logout-icon" />
                            <span className={`description ${reduxIsSidebarCollapsed == true && "d-none"}`}>{reduxLanguageTranslations ? reduxLanguageTranslations["logoutBtnText"] : "Logout"}</span>
                        </span>
                    </span>
                </button>
            </div>
        </div>
    );
};