import React, { Component } from 'react';
import * as ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useEffect } from "react";
import { useState } from 'react';
import axios from 'axios';
import { variables } from '../Variables';
import { useSelector } from "react-redux";
import mindfreeLogo from '../assets/images/login_Logo.svg';

export const EventHandler = () => {
    const user = useSelector(state => state.user.user);
    const { contactId, eventId, clientId, officeId, contactFirstName, contactName, contactLanguage } = useParams();
    const [clientToken, setClientToken] = useState('');
    const [whiseUserToken, setWhiseUserToken] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [whiseCalendarEvents, setWhiseCalendarEvents] = useState([]);
    const [whiseCalendarEventDeleted, setWhiseCalendarEventDeleted] = useState([]);
    const [confirmedIds, setConfirmedIds] = useState([]);
    const [tobeConfirmedIds, setTobeConfirmedIds] = useState([]);
    const [freeSlotIds, setFreeSlotIds] = useState([]);
    const [calendarActions, setCalendarActions] = useState([]);
    const [contactTitleList, setContactTitleList] = useState([]);
    const [estate, setEstate] = useState([]);
    const [whiseClientSettings, setWhiseClientSettings] = useState(null);
    const [localSavedOfficeSettings, setLocalSavedOfficeSettings] = useState(null);
    const handleClose = () => setShowModal(false);

    const [translationsForAllLanguages, setTranslationsForAllLanguages] = useState(null);
    const [selectedLanguageTranslations, setSelectedLanguageTranslations] = useState(null);
    const [selectedContactLanguageTranslations, setSelectedContactLanguageTranslations] = useState(null);

    const getToken = async () => {
        let url = "https://api.whise.eu/token";
        let headers = {
            'Content-Type': 'application/json'
        };
        let body = {
            "Username": variables.WhiseUser,
            "Password": variables.WhisePassword
        };

        try {
            let resp = await axios.post(url, body,
                {
                    headers: headers
                });
            if (resp && resp.data && resp.data.token) {
                setWhiseUserToken(resp.data.token)
                getClientToken(resp.data.token);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getClientToken = (token) => {
        let url = "https://api.whise.eu/v1/admin/clients/token";

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, { "clientId": clientId }, config)
            .then(response => {
                setClientToken(response.data.token);
                getCalendarActions(response.data.token);
                getClientSettings(token, clientId);
                getWhiseCalenderEventById(response.data.token);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getCalendarActions = (clientToken) => {
        let url = "https://api.whise.eu/v1/calendars/actions/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {}, config)
            .then(response => {
                setCalendarActions(response.data.calendarActions);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getWhiseCalenderEventById = (client_Token) => {
        let url = "https://api.whise.eu/v1/calendars/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${client_Token}`,
                'Content-Type': 'application/json'
            },
        };
        let body = {
            "Filter": {
                "CalendarIds": [+eventId],
                "IncludeRecurrences": true
            }
        };
        axios.post(url, body, config)
            .then(response => {
                if (response.data.calendars) {
                    setWhiseCalendarEvents(response.data.calendars);
                    getClientWhiseEstatesList(response.data.calendars[0].estates[0].estateId, client_Token);
                } else {
                    setWhiseCalendarEvents([]);
                }

            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const getClientSettings = (token, clientId) => {
        let url = 'https://api.whise.eu/v1/admin/clients/settings';

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        let body = {
            ClientId: clientId
        };

        axios.post(url, body,
            {
                headers: headers
            })
            .then(response => {
                if (response.data.settings) {
                    setWhiseClientSettings(response.data.settings);
                }
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const getClientWhiseEstatesList = (estateId, client_Token) => {
        let url = "https://api.whise.eu/v1/estates/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${client_Token}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {
            "Filter": {
                "StatusIds": [1],
                "PurposeStatusIds": [1, 2, 5, 6, 10, 11, 12, 13, 15, 16, 20, 21, 27, 28],
                "DisplayStatusIds": [1, 2, 3, 4, 5],
                "EstateIds": [+estateId]
            }
        }, config)
            .then(response => {
                setEstate(response.data.estates);
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const getOfficeSettings = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetLocalSetting?clientId=` + clientId;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let settings = response.data;
                setLocalSavedOfficeSettings(settings);
                let toBeConfirmed = [settings.visitToSaleBefore, settings.visitToRentBefore];
                let cofirmed = [settings.visitToSaleAfter, settings.visitToRentAfter];
                let freeslot = [settings.visitToSaleFreeSlot, settings.visitToRentFreeSlot];

                setTobeConfirmedIds(toBeConfirmed);
                setConfirmedIds(cofirmed);
                setFreeSlotIds(freeslot);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const addWhiseCalenderEvent = async (body) => {
        let url = "https://api.whise.eu/v1/calendars/upsert";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, body, config)
            .then(response => {
                handleClose();


            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const DeleteWhiseCalenerEvent = () => {
        let url = "https://api.whise.eu/v1/calendars/delete";
        let headers = {
            'Authorization': `Bearer ${clientToken}`,
            'Content-Type': 'application/json'
        };
        let body = {
            "CalendarIds": [+eventId]
        };
        axios.delete(url, { data: body, headers: headers })
            .then(response => {
                if (response.data) {
                    setWhiseCalendarEventDeleted(response.data);
                }
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const getAction = (actionId) => {
        let action = calendarActions.filter((d) => d.id === +actionId);
        return action[0].name;
    }

    const getContactTitleList = () => {
        let url = "https://api.whise.eu/v1/contacts/titles/list";
        
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${clientToken}`
        };

        let body = {};

        axios.post(url, body,
            {
                headers: headers
            })
            .then(response => {
                if (response.data) {
                    setContactTitleList(response.data.contactTitles);
                }
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const sendSecondEmail = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        let title = contactTitleList.filter(item => {
            return item.id == whiseCalendarEvents[0].contacts[0].contactTitle.id;
        })

        let visitingDate = new Date(whiseCalendarEvents[0].startDateTime);
        let hours = visitingDate.getHours() < 10 ? "0" + visitingDate.getHours() : visitingDate.getHours();
        let minutes = visitingDate.getMinutes() < 10 ? "0" + visitingDate.getMinutes() : visitingDate.getMinutes();
        let time = hours + ":" + minutes;

        let subject = `${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["confirmationEmailSubjectStaticText1"] : "Confirmation: "} ${title[0].shortDescription + " " + whiseCalendarEvents[0].contacts[0].firstName} ${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["confirmationEmailSubjectStaticText2"] : ", you're visiting the property "} ${whiseCalendarEvents[0].estates[0].estateId} ${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["confirmationEmailSubjectStaticText3"] : " on "} ${visitingDate.toDateString()} ${time}`;

        let estate = whiseCalendarEvents[0].estates[0];

        let html = "<div style='border: 1px solid gray; background-color: white; border-radius: 16px; padding: 20px; width: 80%; margin: 0 auto; text-align: left'>";
        html += "   <div>";
        if (whiseClientSettings != null && whiseClientSettings.logoUrl != undefined) {
            html += "   <img src='" + whiseClientSettings.logoUrl + "' width='200' style='margin: 0 auto; margin-bottom: 1.5rem;' />";
        } else {
            html += "   <img src='" + window.location.origin + "/assets/logo-placeholder.png' width='200' style='margin: 0 auto; margin-bottom: 1.5rem;' />";
        }
        html += "   </div > ";
        html += `   <h5 style='font-size: 24px;font-weight: 500;color: #111827;font-family: Poppins; margin: 0; margin-bottom: 1.5rem'>${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["confirmationEmailVisitRequestHasBeenAcceptedText"] : "Your Visit request has been accepted by"} <strong>${whiseCalendarEvents[0].users[0].name} </strong> ${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["confirmationEmailOfTheAgencyText"] : "of the agency"} ${whiseCalendarEvents[0].users[0].officeId}</h5>`;
        html += "   <div  style='margin-bottom: 1.5rem; border: 1px solid gray; border-radius: 8px; text-align: left; padding: 15px;'>";
        html += `       <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["confirmationEmailVisitingPropertyLocatedText"] : "You're visiting the property located:"}</h5>`;
        html += "       <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>" + (estate.address1 == undefined ? "" : estate.address1) + " " + (estate.number == undefined ? "" : estate.number) + ", " + (estate.zip == undefined ? "" : estate.zip) + " " + (estate.city == undefined ? "" : estate.city) + "</p>";
        html += `       <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem; margin-top: 1.5rem'>${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["eventDenialEmailDateLabel"] : "Date"}</h5>`;
        html += "       <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0;'>" + formatDateTimeLanguageSpecific(contactLanguage, new Date(whiseCalendarEvents[0].startDateTime)) + "</p>";
        html += "   </div>";
        html += "   <div style='margin-bottom: 1.5rem; background-color: #FBEFEF; border-radius: 8px; text-align: left; padding: 15px;'>";
        html += `       <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem; color: #CF132A'>${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["confirmationEmailRemarkLabel"] : "Remarque"}</h5>`;
        html += `       <p style='margin: 0; font-family: Poppins; font-size: 14px;'>${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["confirmationEmailRemarkSectionText"] : "You will receive second email confirmation from the agency with the electronic invitation to add on your agenda. Later, if you're not able to attend to the visit appointment please come back on this email and click on the button below OR call the agency at +32 02 487 878"}</p>`;
        html += "   </div>";
        html += "   <div style='display: flex; align-items: center; justify-content: space-between; font-family: Poppins'>"
        html += `       <a href='#' style='margin-right: 10px; border: 1px solid #111827; text-decoration: none; width: 50%; margin:auto; padding: 9px 10px; border-radius: 8px; text-align: center; color: #111827; font-size: 14px'>${selectedContactLanguageTranslations != null ? selectedContactLanguageTranslations["confirmationEmailCancelVisitButtonText"] : "Cancel the Visit"}</a>`;
        //html += "       <a href='#' style='background-color: #274B97; color: white; text-decoration: none; width: 100%; padding: 10px; border-radius: 8px; text-align: center;font-size: 14px'>Reschedule the Visit</a>"
        html += "   </div>"
        html += "</div> ";


        let email = "";
        if (whiseCalendarEvents[0].contacts[0].privateEmail != undefined && whiseCalendarEvents[0].contacts[0].privateEmail != "") {
            email = whiseCalendarEvents[0].contacts[0].privateEmail;
        }
        else {
            email = whiseCalendarEvents[0].contacts[0].businessEmail;
        }
        let sendTo = email;

        let objEmail = {
            emailTo: sendTo,
            body: html,
            subject: subject,
            emailType: "contact"
        }

        const url = variables.API_URL + `Email/SendEmail?`;
        axios.post(url, JSON.stringify(objEmail), jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                var resp = response.data;

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    function formatDateTimeLanguageSpecific(locale, dateStr) {
        const timeZones = {
            "en-US": "America/New_York",
            "en-GB": "Europe/London",
            "fr-FR": "Europe/Paris",
            "fr-BE": "Europe/Brussels",
            "nl-BE": "Europe/Brussels",
            "de-DE": "Europe/Berlin",
            "es-ES": "Europe/Madrid",
            "zh-CN": "Asia/Shanghai",
            "ja-JP": "Asia/Tokyo",
            "hi-IN": "Asia/Kolkata",
            "ru-RU": "Europe/Moscow",
            "ar-SA": "Asia/Riyadh",
            "pt-BR": "America/Sao_Paulo",
            "en-AU": "Australia/Sydney",
            "en-PK": "Asia/Karachi"
        }
        const eventDate = new Date(dateStr);

        let clientLanguage = "en-GB";
        if (whiseClientSettings != null && whiseClientSettings.defaultLanguageId != undefined) {
            clientLanguage = whiseClientSettings.defaultLanguageId;
        }

        const formatter = new Intl.DateTimeFormat(locale, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,  // Change to true if you need 12-hour format
            timeZoneName: "long", // Change to true if you need 12-hour format
            timeZone: timeZones[clientLanguage], // Specify the desired timezone
            timeZoneName: "long"
        });

        return formatter.format(eventDate);
    }

    const getLanguageTranslations = () => {
        const jsonconfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetTranslationsForSiteByLang?siteName=agent`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let translations = response.data;
                if (Object.keys(translations).length > 0) {
                    setTranslationsForAllLanguages(translations);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (translationsForAllLanguages != null) {
            const translationsForLanguage = translationsForAllLanguages["fr-BE"];
            setSelectedLanguageTranslations(translationsForLanguage);
        }
    }, [translationsForAllLanguages]);

    useEffect(() => {
        if (translationsForAllLanguages != null && contactLanguage) {
            const translationsForLanguage = translationsForAllLanguages[contactLanguage];
            setSelectedContactLanguageTranslations(translationsForLanguage);
        }
    }, [translationsForAllLanguages]);

    useEffect(() => {
        if (whiseUserToken == '') {
            getToken();
            getOfficeSettings();
            getLanguageTranslations();
        }
    }, [whiseUserToken])

    useEffect(() => {
        if (clientToken != "" && contactTitleList.length == 0) {
            getContactTitleList();
        }
        if (clientToken && whiseCalendarEvents.length > 0 && confirmedIds && estate != undefined && estate.length > 0 && contactTitleList.length > 0 && localSavedOfficeSettings != null) {
            let confirmedId = confirmedIds[0];
            if (estate[0].purpose.id === 2) {
                confirmedId = confirmedIds[1];
            }
            let address = "";
            address += estate[0].address == undefined ? "" : estate[0].address + " ";
            address += estate[0].number == undefined ? "" : estate[0].number + ", ";
            address += estate[0].zip == undefined ? "" : estate[0].zip;
            address += estate[0].city == undefined ? "" : " " + estate[0].city;

            let action = calendarActions.filter(item => {
                return item.id == confirmedId;
            })

            let subject = action[0].name + ": " + address + " " + contactFirstName + " " + contactName;
           
            let configration = {
                "CalendarActionId": confirmedId,
                "StartDateTime": whiseCalendarEvents[0].startDateTime,
                "EndDateTime": whiseCalendarEvents[0].endDateTime,
                "AllDay": "false",
                "Private": false,
                "subject": subject,
                "UserIds": [whiseCalendarEvents[0].users[0].userId],
                "EstateIds": [estate[0].id],
                "ContactIds": [+contactId]
            }

            if (whiseCalendarEvents[0].pattern) {
                configration["Pattern"] = whiseCalendarEvents[0].pattern;
                configration["PatternEndDate"] = whiseCalendarEvents[0].patternEndDate;
            }

            DeleteWhiseCalenerEvent();
            addWhiseCalenderEvent(configration);
            if (localSavedOfficeSettings.isAcceptenceEmailDisabled == false || localSavedOfficeSettings.isAcceptenceEmailDisabled == null) {
                sendSecondEmail();
            }
        }

    }, [whiseCalendarEvents, clientToken, estate])

    return (
        <>
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100vh" }}>
                <img src={mindfreeLogo} className="mb-5 pb-5" alt="mindfree logo" />
                <div className="confirmation-message-container text-start">
                    {
                        whiseClientSettings != null && whiseClientSettings.defaultLanguageId != undefined && whiseClientSettings.defaultLanguageId == "en-GB" ? 
                            <>
                                <h6 className="fw-bold">{selectedLanguageTranslations != null ? selectedLanguageTranslations["confirmationScreenMessage"] : `The visit slot will confirmed to the contact in few minutes (event ${eventId}).`} </h6>
                                <h6 className="fw-bold mb-3">{selectedLanguageTranslations != null ? selectedLanguageTranslations["confirmationScreenYouMayCloseThisPageText"] : "You may close this page."}</h6>
                            </>
                            :
                            whiseClientSettings != null && whiseClientSettings.defaultLanguageId != undefined && whiseClientSettings.defaultLanguageId == "fr-BE" ?
                                <>
                                    <h6 className="fw-bold">La réservation de visite sera confirmé au contact dans les prochaines minutes (event {eventId}).</h6>
                                    <h6 className="fw-bold mb-3">Vous pouvez fermer cette page.</h6>
                                </>
                                :
                                whiseClientSettings != null && whiseClientSettings.defaultLanguageId != undefined && whiseClientSettings.defaultLanguageId == "nl-BE" ?
                                    <>
                                        <h6 className="fw-bold">the bezichtiging gaat bevestigd zijn aan de contact in de komende minuten (event {eventId}).</h6>
                                        <h6 className="fw-bold">U mag deze pagina sluiten.</h6>
                                    </>
                                    :
                                    <></>
                    }


                </div>
            </div>
        </>
    )
}