import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';

import { CreateQuestion } from "../eventforms/CreateQuestion";
import { SelectionSummary } from "../eventforms/SelectionSummary";
import { EstateConfigSummary } from "../eventforms/EstateConfigSummary";
import { RequestDocuments } from "../eventforms/RequestDocuments";
import { setquestiondetail, clearquestiondetail } from "../features/QuestionDetailSlice";
import { setestateconfig, clearestateconfig } from "../features/EstateConfigurationSlice";
import { seteventdetail, cleareventdetail } from "../features/CreateEventDetailSlice";
import { variables } from "../Variables";

import closeIcon from '../assets/images/cross-close.svg';
import rightArrowIcon from '../assets/images/rightArrow-icon.svg';
import questionsLoader from '../assets/images/calendar-loader.svg';

import { useState, useEffect } from "react";

export const BookingFormSetup = ({ handleClose, backButtonHandlebookingFormSetup, showMessage, callBackSaveEstateConfigs, isEstateConfigurationsEdit,
    isDocTypeSavedInConfigs, setIsDocTypeSavedInConfigs, setIsQuestionsSavedInConfigs, isQuestionsSavedInConfigs, getEstateIdsHavingConfigSaved }) => {

    const [questionChechBoxActive, setQuestionChechBoxActive] = useState("");
    const [questions, setQuestions] = useState([]);
    const [isCreateQuestion, setIsCreateQuestion] = useState(false);
    const [isQuestionsLoaded, setIsQuestionsLoaded] = useState(false);
    const [isSelectionSummary, setIsSelectionSummary] = useState(false);
    const [isConfigSummary, setIsConfigSummary] = useState(false);
    const [isRequestDocuments, setIsRequestDocuments] = useState(false);
    const [recentlySavedQuestions, setRecentlySavedQuestions] = useState(null);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [selectionOrder, setSelectionOrder] = useState(0);
    const [alreadySavedQuestionsInConfigs, setAlreadySavedQuestionsInConfigs] = useState([]);
    const [inviteeConfigForEstateConfigs, setInviteeConfigForEstateConfigs] = useState(null);
    const [documentsType, setDocumentsType] = useState([]);
    const [isQuestionsNotSelected, setIsQuestionsNotSelected] = useState(false);
    const [requiredFieldMessage, setRequiredFieldMessage] = useState("");
    const [copiedQuestionDetail, setCopiedQuestionDetail] = useState(null);
    const [freeTextForQuestions, setFreeTextForQuestions] = useState(null);
    const [freeTextForDocTypes, setFreeTextForDocTypes] = useState(null);

    const dispatch = useDispatch();
    const reduxQuestionDetail = useSelector(state => state.questionDetail.questionDetail);
    const reduxEstateConfigs = useSelector(state => state.estateConfiguration.estateConfiguration);
    const user = useSelector(state => state.user.user);
    const reduxEstate = useSelector(state => state.selectedProperty.selectedProperty);
    const reduxEventDetail = useSelector(state => state.eventDetail.eventDetail);
    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);
    const reduxSelectedLanguage = useSelector(state => state.selectedLanguageTranslations.selectedLanguage);

    const onClickQuestionCheckbox = (e, item) => {
        let checked = e.target.checked;
        let _selectedQuestions = [...selectedQuestions];
        let _order = selectionOrder;

        // Toggle the isChecked state of the clicked item
        const updatedQuestions = questions.map((question) => {
            if (question.id === item.id) {

                if (recentlySavedQuestions != null && item.id == recentlySavedQuestions.id && checked == false) {
                    setRecentlySavedQuestions(null);
                    dispatch(clearquestiondetail());
                }
                if (_selectedQuestions.length == 0) {
                    _order = checked == true ? _order : _order == 0 ? 0 : _order - 1;
                }
                else {
                    _order = checked == true ? _order + 1 : _order == 0 ? 0 : _order - 1;
                }
                const _questionDetail = {
                    ...JSON.parse(question.questionDetail),
                };

                // If checked, push the updated question to _selectedQuestions
                if (checked) {
                    _selectedQuestions.push({
                        ...question,
                        questionDetail: JSON.stringify(_questionDetail),
                        isChecked: checked,
                        isMandatory: false,
                        order: _order
                    });
                } else {
                    // Otherwise, remove it from _selectedQuestions
                    _selectedQuestions = _selectedQuestions.filter(q => q.id !== item.id);
                    _selectedQuestions = _selectedQuestions.map((q, index) => ({
                        ...q,
                        order: index // Assign new sequential order
                    }));
                }


                return {
                    ...question,
                    questionDetail: JSON.stringify(_questionDetail),
                };

            }
            return question;
        });

        if (_selectedQuestions.length > 0) {
            setIsQuestionsNotSelected(false);
            setRequiredFieldMessage("");
        }

        setSelectionOrder(_order);
        setQuestions(updatedQuestions);
        setSelectedQuestions(_selectedQuestions);
    }

    const getEstateConfigurationsFromDb = (isCallingInEditMode) => {
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Calendar/GetEstateConfigurations?clientId=${user.user.clientId}&estateId=${reduxEstate.id}`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let _estateConfig = response.data;
                if (_estateConfig != null) {

                    //load required states when edit configurations form get opened
                    if (isCallingInEditMode) {

                        let inviteeConfig = JSON.parse(_estateConfig.configurationDetail).inviteeConfig;

                        setAlreadySavedQuestionsInConfigs(JSON.parse(_estateConfig.configurationDetail).questions);
                        setDocumentsType(JSON.parse(_estateConfig.configurationDetail).requiredDocuments);
                        setInviteeConfigForEstateConfigs(inviteeConfig);

                        if (inviteeConfig.Documents == true && inviteeConfig.Question == false) {
                            setIsRequestDocuments(true);
                        }

                        if (JSON.parse(_estateConfig.configurationDetail).requiredDocuments.length > 0) {
                            setIsDocTypeSavedInConfigs(true);
                        }
                        if (JSON.parse(_estateConfig.configurationDetail).questions.length > 0) {
                            setIsQuestionsSavedInConfigs(true);
                        }
                        if (JSON.parse(_estateConfig.configurationDetail).freeTextForQuestions) {
                            setFreeTextForQuestions(JSON.parse(JSON.parse(_estateConfig.configurationDetail).freeTextForQuestions));
                        }
                        if (JSON.parse(_estateConfig.configurationDetail).freeTextForDocTypes) {
                            setFreeTextForDocTypes(JSON.parse(JSON.parse(_estateConfig.configurationDetail).freeTextForDocTypes));
                        }
                    }
                    else {
                        //load required states to verify if configurations are already saved just question and documents checkboxes values are different on front end
                        setAlreadySavedQuestionsInConfigs(JSON.parse(_estateConfig.configurationDetail).questions);
                        setDocumentsType(JSON.parse(_estateConfig.configurationDetail).requiredDocuments);
                        dispatch(setestateconfig(JSON.parse(_estateConfig.configurationDetail).questions));
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (isEstateConfigurationsEdit == true) {
            getEstateConfigurationsFromDb(true);
        }
    }, [isEstateConfigurationsEdit])

    const getQuestions = () => {
        const config = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            },
        };
        const url = variables.API_URL + `calendar/GetQuestions?clientId=${user.user.clientId}`;
        axios.get(url, config)
            .then(response => {
                let questionsList = response.data;
                setQuestions(questionsList);
                setIsQuestionsLoaded(true);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (alreadySavedQuestionsInConfigs.length > 0 && questions.length > 0) {
            let mappedQuestions = questions.map((item) => {
                // Find the corresponding question in alreadySavedQuestionsInConfigs
                const matchingQuestion = alreadySavedQuestionsInConfigs.find(q => q.questionId === item.id);

                // If there's a match, merge the properties from `q` to `item`
                if (matchingQuestion) {
                    return { ...item, isChecked: false, isMandatory: matchingQuestion.isMandatory, order: matchingQuestion.order }; // Existing properties in `item` take precedence
                }

                // If no match is found, return the item as-is
                return item;
            })

            let filteredQuestions = mappedQuestions.filter(item => alreadySavedQuestionsInConfigs.some(q => q.questionId === item.id));

            const maxOrder = filteredQuestions.reduce((max, item) => {
                return item.order > max ? item.order : max;
            }, 0);

            setSelectionOrder(maxOrder);
            setSelectedQuestions(filteredQuestions);
        }
    }, [alreadySavedQuestionsInConfigs])

    useEffect(() => {
        if (reduxQuestionDetail != null && reduxQuestionDetail.id != undefined) {
            //get recently saved question detail from redux and add order + isMandatory properties in the object
            let _recentSavedQuestionDetail = { ...reduxQuestionDetail };

            //update order state when question is created and got selected
            //setSelectionOrder(0);

            //get exisiting selected questions list and push newly created question in it
            let _selectedQuestions = [...selectedQuestions];
            let maxOrder = _selectedQuestions.length > 0 ? Math.max(..._selectedQuestions.map(o => o.order)) : 0;
            if (copiedQuestionDetail != null) {
                _recentSavedQuestionDetail.order = copiedQuestionDetail.order;
                _recentSavedQuestionDetail.isMandatory = copiedQuestionDetail.isMandatory;
                let _selectedQuestionsWithoutEditedQuestion = _selectedQuestions.filter(item => {
                    return item.id != copiedQuestionDetail.id;
                })
                _selectedQuestionsWithoutEditedQuestion.push({ ..._recentSavedQuestionDetail });
                setSelectionOrder(_selectedQuestionsWithoutEditedQuestion.length - 1);
                setSelectedQuestions(_selectedQuestionsWithoutEditedQuestion);
                setRecentlySavedQuestions({ ..._recentSavedQuestionDetail });
                getQuestions();
                setIsQuestionsNotSelected(false);
                setRequiredFieldMessage("");
                dispatch(setestateconfig(_selectedQuestionsWithoutEditedQuestion));
            }
            else {
                _recentSavedQuestionDetail.order = _selectedQuestions.length > 0 ? maxOrder + 1 : 0;
                _recentSavedQuestionDetail.isMandatory = false;
                _selectedQuestions.push({ ..._recentSavedQuestionDetail });
                setSelectionOrder(_selectedQuestions.length - 1);
                setSelectedQuestions(_selectedQuestions);
                setRecentlySavedQuestions({ ..._recentSavedQuestionDetail });
                getQuestions();
                setIsQuestionsNotSelected(false);
                setRequiredFieldMessage("");
            }
        }
    }, [reduxQuestionDetail])

    //const onClickSelectionCheckbox = (item) => {

    //    // Update selections to ensure only the selected checkbox is checked
    //    const updatedSelections = selections.map((selection) => {
    //        // Set isChecked to true only for the selected item and false for others
    //        if (selection.selectionId === item.selectionId) {
    //            return { ...selection, isChecked: !selection.isChecked };
    //        } else {
    //            return { ...selection, isChecked: false };
    //        }
    //    });
    //    // Update state with new array reference
    //    setSelections(updatedSelections);

    //    // Toggle the isChecked state of the clicked item
    //    const updatedQuestions = questions.map((question) => {
    //        return { ...question, isChecked: false };
    //    });
    //    setQuestions(updatedQuestions);
    //}

    const closeModalFromBookingSetupForm = () => {
        dispatch(clearquestiondetail());
        setRecentlySavedQuestions(null);
        dispatch(clearestateconfig());
        handleClose();
    }

    const createQuestionHandle = () => {
        dispatch(clearquestiondetail());
        setIsCreateQuestion(true);
    }

    //this method will close Question creation modal
    const closeCreateQuestionModal = () => {
        if (copiedQuestionDetail == null) {
            setIsCreateQuestion(false);
        }
        else {
            setIsCreateQuestion(false);
            setIsSelectionSummary(true);
            setCopiedQuestionDetail(null);
        }
    }

    //show selection summary screen
    const previewSummaryOfQuestions = () => {

        if (selectedQuestions.length == 0) {
            setIsQuestionsNotSelected(true);
            setRequiredFieldMessage(reduxLanguageTranslations ? reduxLanguageTranslations["bookingFormModalValidErrorMessage"]: "Please select the question");
            return;
        }

        //setestateconfig redux store only contain selected questions, need to change reducer name
        dispatch(setestateconfig(selectedQuestions));
        setIsCreateQuestion(false);
        setIsConfigSummary(false);
        setIsRequestDocuments(false);
        setIsSelectionSummary(true);

    }

    //this method will close summary modals
    const closeConfigSavingModal = () => {
        setIsSelectionSummary(false);
        setIsConfigSummary(false);
        setIsRequestDocuments(false);
        dispatch(clearestateconfig());

        if (!isEstateConfigurationsEdit) {
            if (reduxEventDetail != null && reduxEventDetail.invitee != null && isDocTypeSavedInConfigs == false && reduxEventDetail.invitee.Documents) {
                if (isQuestionsSavedInConfigs == false && reduxEventDetail.invitee.Question == true) {
                    setIsSelectionSummary(false);
                }
                else {
                    callBackSaveEstateConfigs(false);
                }
            }
        }
        else if (inviteeConfigForEstateConfigs.Question == false) {
            closeModalFromBookingSetupForm()
        }
    }

    //move to next screen (configuration summary) from document type screen 
    const callBackMoveToConfigSummaryFromDocType = () => {
        setIsSelectionSummary(false);
        setIsRequestDocuments(false);
        setIsConfigSummary(true);
    }

    //move user from Selection summary screen to Configuration summary
    const callBackMoveToConfigSummary = () => {
        if (inviteeConfigForEstateConfigs && inviteeConfigForEstateConfigs.Documents) {
            setIsSelectionSummary(false);
            setIsRequestDocuments(true);
        }
        else {
            setIsSelectionSummary(false);
            setIsConfigSummary(true);
        }

    }

    //this will move user from document type screen to selection summary screen
    const clickHandleMoveBackToSelectionSummary = () => {
        if (reduxEventDetail != null && reduxEventDetail.invitee != null && isDocTypeSavedInConfigs == false && reduxEventDetail.invitee.Documents) {
            if (isQuestionsSavedInConfigs == false && reduxEventDetail.invitee.Question == true) {
                setIsConfigSummary(false);
                setIsRequestDocuments(false);
                setIsSelectionSummary(true);
            }
            else {
                setIsConfigSummary(false);
                setIsRequestDocuments(false);
                setIsSelectionSummary(false);
                callBackSaveEstateConfigs(false);
            }
        }
        else if (isEstateConfigurationsEdit) {
            if (inviteeConfigForEstateConfigs.Question == true) {
                setIsConfigSummary(false);
                setIsRequestDocuments(false);
                setIsSelectionSummary(true);
            }
            else {
                closeModalFromBookingSetupForm()
            }
        }

        //else if (isEstateConfigurationsEdit == true && isQuestionsSavedInConfigs){

        //}

        //setIsConfigSummary(false);
        //setIsRequestDocuments(false);
        //setIsSelectionSummary(true);
    }

    //this will move user from configuration summary screen to selection summary / document type screen
    const clickHandleMoveBackToPreviousScreen = () => {
        if (inviteeConfigForEstateConfigs.Documents) {
            setIsSelectionSummary(false);
            setIsConfigSummary(false);
            setIsRequestDocuments(true);
        }
        else {
            setIsConfigSummary(false);
            setIsRequestDocuments(false);
            setIsSelectionSummary(true);
        }

        //setIsConfigSummary(false);
        //setIsRequestDocuments(false);
        //setIsSelectionSummary(true);
    }

    //save estate configurations with questions + document types + invite configurations in db 
    const saveEstateConfigurations = () => {
        let configs = reduxEstateConfigs;
        let documents = [...documentsType];
        let invitee = { ...inviteeConfigForEstateConfigs };

        if (configs && configs.length > 0) {
            invitee.Question = true;
        }

        if (isDocTypeSavedInConfigs && isEstateConfigurationsEdit == false) {
            invitee.Documents = true;
        }

        let objEstateConfigs = {
            questions: reduxEstateConfigs ? reduxEstateConfigs.map(item => { return { questionId: item.id ? item.id : item.questionId, order: item.order, isMandatory: item.isMandatory } }) : [],
            inviteeConfig: invitee,
            requiredDocuments: documents,
            freeTextForQuestions: JSON.stringify(freeTextForQuestions),
            freeTextForDocTypes: JSON.stringify(freeTextForDocTypes)
        }

        let objFinalEstateConfigs = {
            Id: 0,
            ConfigurationDetail: JSON.stringify(objEstateConfigs),
            EstateId: reduxEstate.id,
            ClientId: +user.user.clientId
        }

        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Calendar/SaveEstateConfiguration?`;
        axios.post(url, JSON.stringify(objFinalEstateConfigs), jsonconfig) // ASP.NET Core API endpoint with header
            .then(response => {
                showMessage("success", reduxLanguageTranslations ? reduxLanguageTranslations["bookingSetupModalEstateConfigSuccessText"] : "Estate configuration successfully saved.");
                setIsConfigSummary(false);
                setIsSelectionSummary(false);
                setIsCreateQuestion(false);
                dispatch(clearestateconfig());
                dispatch(clearquestiondetail());
                setSelectedQuestions([]);
                callBackSaveEstateConfigs(true);
                setSelectionOrder(0);
                setAlreadySavedQuestionsInConfigs([]);
                setDocumentsType([]);
                setIsRequestDocuments(false);
                getEstateIdsHavingConfigSaved();
                //if (isEstateConfigurationsEdit) {
                //    dispatch(cleareventdetail());
                //}
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const createEditQuestion = (item) => {

        setIsCreateQuestion(true);
        setIsSelectionSummary(false);
        setCopiedQuestionDetail(item);
    }

    //get questions list saved in db when user is authenticated
    useEffect(() => {
        if (user) {
            getQuestions();
        }
    }, [user])

    useEffect(() => {
        if (reduxEventDetail != null && reduxEventDetail.invitee != null) {
            getEstateConfigurationsFromDb(false); //this method is calling here just to get already saved configurations
            setInviteeConfigForEstateConfigs(reduxEventDetail.invitee);
        }
    }, [reduxEventDetail])

    //open documents type modal when documents checkbox is checked and configurations are saved but document types are not saved
    useEffect(() => {
        if (inviteeConfigForEstateConfigs != null && (inviteeConfigForEstateConfigs.Question == false || isQuestionsSavedInConfigs) && isEstateConfigurationsEdit == false) {
            if (inviteeConfigForEstateConfigs.Documents == true && isDocTypeSavedInConfigs == false) {
                setIsRequestDocuments(true);
            }
            if (isEstateConfigurationsEdit && inviteeConfigForEstateConfigs.Question == false) {
                setIsRequestDocuments(true);
            }
        }
    }, [isDocTypeSavedInConfigs, inviteeConfigForEstateConfigs, isQuestionsSavedInConfigs])

    return (
        <>
            {
                isCreateQuestion == false && isSelectionSummary == false && isConfigSummary == false && isRequestDocuments == false ?
                    <>
                        <div className="d-flex justify-content-between mb-3">
                            <ul className="form-steps">
                                <li className="step-bar"></li>
                                <li className="step-bar"></li>
                                <li className="step-bar"></li>
                                <li className="step-bar active"></li>
                                <li className="step-bar"></li>
                                <li className="step-bar"></li>
                                <li className="step-bar"></li>
                            </ul>
                            <img src={closeIcon} onClick={closeModalFromBookingSetupForm} className="cursor-pointer" />
                        </div>
                        <div>
                            <p className="form-title mb-2">{reduxLanguageTranslations ? reduxLanguageTranslations["bookingFormModalTitle"] : "Setup of the booking form"}</p>
                        </div>
                        <div>
                            <p className="subtitle mb-1">{reduxLanguageTranslations ? reduxLanguageTranslations["bookingFormModalSubTitle"] : "Add questions"}</p> 
                            <p className="modal-p-text-black mb-4">
                                {reduxLanguageTranslations ? reduxLanguageTranslations["bookingFormModalLoremText"] :
                                    "Lorem ipsum dolor sit amet consectetur. Ullamcorper netus sed est lorem. Facilisis dictum bibendum tellus eu eget porttitor. Arcu eget molestie sed consectetur integer. Risus malesuada id lacus egestas. Sit maecenas iaculis nibh eget habitasse ac."
                                }
                            </p>
                        </div>
                        <div>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="1" className={`${isQuestionsNotSelected ? "border-red" : ""} mb-0`}>
                                    <Accordion.Header>
                                        {reduxLanguageTranslations ? reduxLanguageTranslations["bookingFormModalQuestionDropdownSelectQu"] : "Select Questions"}
                                        <span className="required-icon">*</span> <img src={questionsLoader} width="25" className={`rotating-loader ms-2 ${isQuestionsLoaded == false ? "" : "d-none"}`} />
                                    </Accordion.Header>
                                    <Accordion.Body className="question-accordion-body">
                                        {
                                            questions.length > 0 ? questions.map((item) => {
                                                let isChecked = false;
                                                let detail = JSON.parse(item.questionDetail);
                                                let isFound = selectedQuestions.some(q => q.id == item.id);

                                                if (isFound == true) {
                                                    isChecked = isFound;
                                                }
                                                if (reduxQuestionDetail != null && item.id == reduxQuestionDetail.id) {
                                                    isChecked = true;
                                                }

                                                return (
                                                    <label className={`modal-p-text-black question-div d-flex align-items-center mb-2 ${isChecked == true ? "question-bg" : ""}`} key={item.id} htmlFor={`checkbox${item.id}`}>
                                                        <input className="form-check-input question-check-box me-2" type="checkbox" checked={isChecked} id={`checkbox${item.id}`} onChange={(e) => onClickQuestionCheckbox(e, item)} />
                                                        {detail[`questionTitle-${reduxSelectedLanguage}`]}
                                                    </label>
                                                )
                                            })
                                                :
                                                <>
                                                </>
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*<Accordion.Item eventKey="2">*/}
                                {/*    <Accordion.Header>*/}
                                {/*        Select a Selection **/}
                                {/*    </Accordion.Header>*/}
                                {/*    <Accordion.Body>*/}
                                {/*        {*/}
                                {/*            selections ? selections.map((item) => {*/}
                                {/*                return (*/}
                                {/*                    <label className={`modal-p-text-black question-div d-flex align-items-center mb-2 ${item.isChecked == true ? "question-bg" : ""}`} key={item.selectionId} onClick={() => onClickSelectionCheckbox(item)}>*/}
                                {/*                        <input className="form-check-input question-check-box me-2" type="checkbox" checked={item.isChecked} id={`selection${item.selectionId}`} onClick={(e) => e.stopPropagation()} />*/}
                                {/*                        {item.selectionTitle}*/}
                                {/*                    </label>*/}
                                {/*                )*/}
                                {/*            })*/}
                                {/*                :*/}
                                {/*                <></>*/}
                                {/*        }*/}
                                {/*    </Accordion.Body>*/}
                                {/*</Accordion.Item>*/}
                            </Accordion>
                            <p className="text-red fs-small login-field-error">{requiredFieldMessage}</p>
                        </div>
                        <button className="btn create-question-btn w-100 d-flex justify-content-between mb-3" onClick={createQuestionHandle}>
                            <span>{reduxLanguageTranslations ? reduxLanguageTranslations["bookingFormModalCreateQuestionBtnText"] : "Create a new question"}</span>
                            <span><img src={rightArrowIcon} /></span>
                        </button>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-25 me-3" onClick={backButtonHandlebookingFormSetup}>{reduxLanguageTranslations ? reduxLanguageTranslations["bookingFormModalBackBtn"] : "Back"}</button>
                            <button type="button" className="site-btn site-btn-padd w-25" onClick={previewSummaryOfQuestions}>{reduxLanguageTranslations ? reduxLanguageTranslations["bookingFormModalNextBtn"] : "Next"}</button>
                        </div></>
                    :
                    isConfigSummary == false && isSelectionSummary == false && isRequestDocuments == false && isCreateQuestion == true ?
                        <CreateQuestion closeCreateQuestionModal={closeCreateQuestionModal} showMessage={showMessage} copiedQuestion={copiedQuestionDetail} />
                        :
                        isConfigSummary == false && isCreateQuestion == false && isRequestDocuments == false && isSelectionSummary == true ?
                            <SelectionSummary closeSelectionSummaryModal={closeConfigSavingModal} maxOrder={selectionOrder}
                                clickHandlerMoveToConfigSummary={callBackMoveToConfigSummary} setSelectedQuestions={setSelectedQuestions} createEditQuestion={createEditQuestion}
                                freeTextForQuestions={freeTextForQuestions} setFreeTextForQuestions={setFreeTextForQuestions}
                            />
                            :
                            isSelectionSummary == false && isCreateQuestion == false && isConfigSummary == false && isRequestDocuments == true ?
                                <RequestDocuments closeRequestDocumentsModal={closeConfigSavingModal} moveBackToSelectionSummary={clickHandleMoveBackToSelectionSummary}
                                    moveToConfigSummaryFromDocType={callBackMoveToConfigSummaryFromDocType} documentsType={documentsType} setDocumentsType={setDocumentsType}
                                    freeTextForDocTypes={freeTextForDocTypes} setFreeTextForDocTypes={setFreeTextForDocTypes}
                                />
                                :
                                isSelectionSummary == false && isCreateQuestion == false && isRequestDocuments == false && isConfigSummary == true ?
                                    <EstateConfigSummary closeConfigModal={closeConfigSavingModal} clickHandleMoveBackToPreviousScreen={clickHandleMoveBackToPreviousScreen}
                                        saveEstateConfigurations={saveEstateConfigurations} documentsType={documentsType} freeTextForQuestions={freeTextForQuestions}
                                        freeTextForDocTypes={freeTextForDocTypes}
                                    />
                                    :
                                    <></>
            }
        </>
    )
}