import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setquestiondetail, clearquestiondetail } from "../features/QuestionDetailSlice";
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';

import closeIcon from '../assets/images/cross-close.svg';
import cogWithBg from '../assets/images/cog-wd-bg.svg';
import editIcon from '../assets/images/edit-icon.svg';
import { variables } from '../Variables';

export const QuestionSummary = ({ closeQuestionCreationForm, moveToNextStep, moveToPreviousStep, languages, showMessage, handleEditQuestion }) => {
    const [answerType, setAnswerType] = useState(0);
    const [answerOptions, setAnswerOptions] = useState([]);
    const [questionDetail, setQuestionDetail] = useState(null);
    const dispatch = useDispatch();
    const reduxQuestionDetail = useSelector(state => state.questionDetail.questionDetail);
    const user = useSelector(state => state.user.user);
    const reduxLanguageTranslations = useSelector(state => state.selectedLanguageTranslations.selectedLanguageTranslations);

    useEffect(() => {
        if (reduxQuestionDetail.questionDetail.answerOptions) {
            setQuestionDetail(reduxQuestionDetail);
            setAnswerOptions(reduxQuestionDetail.questionDetail.answerOptions);
        }
        if (reduxQuestionDetail.questionDetail.answerType) {
            setAnswerType(reduxQuestionDetail.questionDetail.answerType);
        }
    }, [reduxQuestionDetail])

    const handleKeyDown = (e) => {
        // Prevent up and down arrow keys from modifying the value
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
        }
    };

    const saveQuesitonHandle = () => {
        if (questionDetail == null) {
            return;
        }
        const config = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            },
        };

        let objSaveQuestion = {
            clientId: questionDetail.clientId,
            questionDetail: JSON.stringify(questionDetail.questionDetail)
        }

        const url = variables.API_URL + `calendar/SaveQuestion`;
        axios.post(url, JSON.stringify(objSaveQuestion), config)
            .then(response => {
                let getQuestionValue = response.data;
                showMessage("success", reduxLanguageTranslations ? reduxLanguageTranslations["quesSummaryModalQuSuccessCreate"] : "Question successfully created.");
                closeQuestionCreationForm();
                dispatch(setquestiondetail(getQuestionValue));
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                showMessage("error", error);
            });
    }

    return (
        <>
            <div className="d-flex justify-content-between mb-4">
                <ul className="form-steps">
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar active"></li>
                </ul>
                <img src={closeIcon} onClick={closeQuestionCreationForm} className="cursor-pointer" />
            </div>
            <div className="mb-4">
                <p className="form-title mb-4">{reduxLanguageTranslations ? reduxLanguageTranslations["quesSummaryModalTitle"] : "Summary"}</p>
            </div>
            <div className="tabs-container">
                <Tabs defaultActiveKey="English" id="justify-tab-example" className="mb-3" justify>
                    {
                        languages ? languages.map(item => {

                            let languageLable = "";
                            if (item.languageDescription == "English") {
                                languageLable = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalEnglishLbl"] : "English";
                            }
                            else if (item.languageDescription == "French") {
                                languageLable = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalFrenchLbl"] : "French";
                            }
                            else if (item.languageDescription == "Dutch") {
                                languageLable = reduxLanguageTranslations ? reduxLanguageTranslations["createQuestionModalDutchLbl"] : "Dutch";
                            }

                            const yesLabel = item.languageCode === "en-GB" ? "Yes" : item.languageCode === "fr-BE" ? "Oui" : "Ja";
                            const noLabel = item.languageCode === "en-GB" ? "No" : item.languageCode === "fr-BE" ? "Non" : "Nee";

                            return (
                                <Tab eventKey={item.languageDescription} title={languageLable} key={item.id}>
                                    <div className="question-summary-container p-3 mb-4">
                                        <div className="mb-4">
                                            <div className="position-relative">
                                                <Dropdown>
                                                    <Dropdown.Toggle className="del-question-drop-btn bg-transparent position-absolute end-0 border-0 p-0" variant="light" id={`editQuestionDropdownButton{item.id}`}>
                                                        <img src={cogWithBg} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="left-aligned-dropdown del-question-menu text-center p-0">
                                                        <button className="btn btn-white small-grey-text text-dark d-flex align-items-center" onClick={handleEditQuestion}>
                                                            <img src={editIcon} className="me-1" />{reduxLanguageTranslations ? reduxLanguageTranslations["quesSummaryModalEditBtn"] : "Edit"}
                                                        </button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <p className="subtitle mb-0">{reduxLanguageTranslations ? reduxLanguageTranslations["quesSummaryModalQuestionTitle"] : "Title of the question"}</p>
                                            <p className="modal-p-text-black mb-0">
                                                {reduxQuestionDetail.questionDetail ? reduxQuestionDetail.questionDetail["questionTitle-" + item.languageCode] : ""}
                                            </p>
                                        </div>
                                        <div className="mb-4">
                                            <p className="subtitle mb-0">{reduxLanguageTranslations ? reduxLanguageTranslations["quesSummaryModalDescQuestion"] : "Description of the question"}</p>
                                            <p className="modal-p-text-black mb-0">
                                                {reduxQuestionDetail.questionDetail ? reduxQuestionDetail.questionDetail["questionDescription-" + item.languageCode] : ""}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="subtitle mb-1">{reduxLanguageTranslations ? reduxLanguageTranslations["quesSummaryModalAnswersLabel"] : "Answers"}</p>
                                            <div>
                                                {
                                                    answerType == 2 || answerType == 3 && answerOptions.length > 0 ?
                                                        answerOptions.map((option) => {
                                                            let label = option[`option${option.id}-${item.languageCode}`];
                                                            return (
                                                                <div className="mb-2" key={option.id}>
                                                                    <label className="modal-p-text-black asnwer-options-preview d-flex align-items-center mb-2 w-100 me-2 line-height-0" htmlFor={`answerOption${item.id}${option.id}`}>
                                                                        <input className="custom-radio me-2" type="radio" name={`answerOption${item.id}`} id={`answerOption${item.id}${option.id}`} />
                                                                        {
                                                                            label
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        answerType == 1 ?
                                                            <div className="d-flex col-4">
                                                                <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-50 line-height-0" htmlFor={`radio${item.id}`}>
                                                                    <input className="custom-radio me-2" type="radio" name={`radio${item.id}`} id={`radio${item.id}`} />
                                                                    {/*{reduxLanguageTranslations ? reduxLanguageTranslations["quesSummaryModalAnsRadioOptionYes"] : "Yes"}*/}
                                                                    {reduxLanguageTranslations ? yesLabel : reduxLanguageTranslations["quesSummaryModalAnsRadioOptionYes"]}
                                                                </label>

                                                                <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-50 line-height-0" htmlFor={`radio${item.id}${answerType}`}>
                                                                    <input className="custom-radio me-2" type="radio" name={`radio${item.id}`} id={`radio${item.id}${answerType}`} />
                                                                    {/*{reduxLanguageTranslations ? reduxLanguageTranslations["quesSummaryModalAnsRadioOptionNo"] : "No"}*/}
                                                                    {reduxLanguageTranslations ? noLabel : reduxLanguageTranslations["quesSummaryModalAnsRadioOptionNo"]}
                                                                </label>
                                                            </div>
                                                            :
                                                            answerType == 4 || answerType == 5 ?
                                                                <>
                                                                    {
                                                                        answerType == 4 ?
                                                                            <input type="number" className={`form-control bg-white mb-2 no-spinner`}
                                                                                onPaste={(e) => e.preventDefault()} onKeyDown={handleKeyDown}
                                                                            />
                                                                            :
                                                                            <input type="text" className={`form-control bg-white mb-2`} />
                                                                    }
                                                                    
                                                                </>
                                                            :
                                                                <>
                                                                    <input type="date" className="form-control bg-white mb-2" />
                                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            )
                        })
                            :
                            <></>
                    }
                </Tabs>
            </div>
            <div className="d-flex justify-content-center">
                <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-25 me-3" onClick={moveToPreviousStep}>{reduxLanguageTranslations ? reduxLanguageTranslations["typeOfAnsModalBackBtn"] : "Back"}</button>
                {
                    questionDetail ?
                        <button type="button" className="site-btn site-btn-padd w-25" onClick={saveQuesitonHandle}>{reduxLanguageTranslations ? reduxLanguageTranslations["typeOfAnsModalNextBtn"] : "Next"}</button>
                        :
                        <button type="button" className="site-btn site-btn-padd w-25" disabled onClick={saveQuesitonHandle}>{reduxLanguageTranslations ? reduxLanguageTranslations["typeOfAnsModalNextBtn"] : "Next"}</button>
                }
            </div>
        </>
    )
}
